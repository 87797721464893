import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useState,useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";

const Viewpodretrotalent = ({Podid}) => {
    const [retrolisting, setRetrolisting] = useState([]);
    const [currentPageretro, setCurrentPageretro] = useState(1);
    const itemsPerPageretro = 4; // Number of items per page
    const hasMorePages = retrolisting.length === itemsPerPageretro; // Assume there are more pages if the number of fetched items equals itemsPerPage
    const token = useAuthToken();


    const location = useLocation();
 
    
    const fetchretro = async () => {
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
        try {
          const response = await axios.post(process.env.REACT_APP_CLIENT_PODRETROLIST, {
            pods_id: Podid,
          },{headers:headers});
    
          const responseData = response.data.data;
          
          setRetrolisting(responseData);
        } catch (error) {
          console.error('Error fetching candidates:', error);
        }
      };
  
      useEffect((token) => {
      
          fetchretro();
        
      }, [token]);
      

      const handlePageChangeretro = (newPage) => {
        // fetchretro(PodID); // No need to send page as a parameter
        setCurrentPageretro(newPage);

      };
      const totalPages = Math.ceil(retrolisting.length / itemsPerPageretro);

      // Calculate the startIndex and endIndex based on currentPage
      const startIndex = (currentPageretro - 1) * itemsPerPageretro;
      const endIndex = Math.min(startIndex + itemsPerPageretro, retrolisting.length);
      
      // Get the items for the current page
      const itemsOnPage = retrolisting.slice(startIndex, endIndex);
      

  return (
    <Div style={{marginTop:'12rem'}}>
    <TableContainer component={Paper} sx={{marginTop: '2rem'}}>
    <Table component={Paper} sx={{ width: 900 ,backgroundColor:'#90E0EF',fontFamily:'Poppins,Medium', borderRadius: '10px'}}aria-label="simple table">
      <TableHead     sx={{ backgroundColor:'#00B4D8','& td, & th': {
        fontFamily: 'Poppins,Medium',
        fontSize: '15px',
        color: 'white',
        fontWeight:'600'
      },}}>
        <TableRow    > 

        {/* <TableCell align="right">Select</TableCell> */}
         
          <TableCell align="right">Pod Feedback</TableCell>
         
          <TableCell align="right"sx={{fontWeight:'bold'}}>Sprint</TableCell>
          
        
        </TableRow>
      </TableHead>
   
      {/* records tulasi*/}  
      <TableBody>
{itemsOnPage.length > 0 ? itemsOnPage.map((item) => 
<TableRow key={item.sprint_id}>
<TableCell align="right">{item.retro}  </TableCell>
<TableCell align="right">{item.sprint}</TableCell>
</TableRow>
) : (
<TableRow>
<TableCell colSpan={2} align="center">
There are no reviews.
</TableCell>
</TableRow>
)}
</TableBody>
{/* Pagination */}
<TableFooter>
<TableRow>
<TablePagination
rowsPerPageOptions={[]}
colSpan={2} // Update this to match the number of table cells you have per row
count={retrolisting.length}
rowsPerPage={itemsPerPageretro}
page={currentPageretro - 1}
onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
ActionsComponent={(props) => (
<TablePaginationActions
  {...props}
  count={retrolisting.length}
  page={currentPageretro - 1}
  onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
/>
)}
/>
</TableRow>
</TableFooter>

        
    </Table>
    {/* <Button onClick={handleSubmit}>Approve</Button> */}
          
    </TableContainer>
    </Div>
  )
}
const Div =styled.div`

display:flex;

jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:auto;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;

  }
`;
export default Viewpodretrotalent
