import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './kanband.css';
import Select from 'react-select';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const KanbanBoard = () => {
  const [tasks, setTasks] = useState([]);
  const [todo, setTodo] = useState([]);
  const [inprogress, setInprogress] = useState([]);
  const [done, setDone] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [selectedPodId, setSelectedPodId] = useState(null);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const token = useAuthToken();
  const intl = useIntl();


  // ...
  const [podNames, setPodNames] = useState([]);
  useEffect(() => {
    fetchAuthorizationTokensAndList();
    fetchPodNames();
  }, [selectedPodId,token]);
  useEffect(()=>{
    
  })
  const fetchPodNames = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(
        //https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/fetch_pods_for_manager
        process.env.REACT_APP_CLIENT_FETCHPODSFORMANAGER,
        { clientID: user.data[0].emp_id }, {headers:headers} // Use the appropriate client ID
      );
   
      const data = response.data.data;
  
      // Extract pod names and IDs from the response
      const podNameOptions = data.map((pod) => ({
        label: pod.podName,
        value: pod.id,
      }));
  
      setPodNames(podNameOptions);
    } catch (error) {
      console.error('Error fetching pod names:', error);
    }
  };
  
 
  


  const fetchAuthorizationTokensAndList = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      if (!selectedPodId) {
        // Don't make the API request if a pod is not selected
        return;
      }
  
      // Fetch authorization tokens
      const authorizationPayload = {
        Organizational_Code: user.data[0].client_code,
      };
     
      const authorizationResponse = await axios.post(
        //https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/fetch_authorization_tokens
 process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        authorizationPayload, {headers:headers}
      );
  
      const authorizationData = authorizationResponse.data.body.data[0];
  
    // Store authorization details in state
    setAuthorizationDetails(authorizationData);
      // Extract the necessary tokens and URLs from the authorization response
      const jiraUrl = authorizationData.Jira_URL;
      const jiraAdminEmail = authorizationData.Jira_admin_login_email_address;
      const jiraAdminToken = authorizationData.Jira_admin_API_Token;
      const githubUsername = authorizationData.Github_username;
      const githubApiToken = authorizationData.Github_API_Token;
      const projectKey=authorizationData.Jira_Kanben_Project_name
      // ... Extract other tokens and URLs as needed
     
      const emailFetchingResponse = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
        {
          podID: selectedPodId,
        },{headers:headers}
      );
  
      const emailAddresses = emailFetchingResponse.data.data.map(
        (candidate) => candidate.email
      );
  

      // Construct the payload for the list API using the obtained data
      const listPayload = {
        pod_Id: selectedPodId, // Use the selected pod ID
        username: jiraAdminEmail, // Use the Jira admin email from authorization data
        password: jiraAdminToken, // Use the Jira admin token from authorization data
        projectKey: projectKey,
        jira_url: jiraUrl, // Use the Jira URL from authorization data
        assignees: emailAddresses, 
      };
  
      try {
        const response = await axios.post(
          //https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/single-view-tasks
          process.env.REACT_APP_CLIENT_SINGLEVIEWTASK,
          listPayload,{headers:headers}
        );
        const podMembers = response.data.data.podMembers;
        const jiraIssues = response.data.data.jiraIssues;
  
        // Combine tasks into a single array, including podMembers
        const combinedTasks = [...podMembers, ...jiraIssues];
  
        const Done = combinedTasks.filter((task) =>
          task && task.status && task.status.toLowerCase() === 'done'
        );
  
        const InProgress = combinedTasks.filter((task) =>
          task &&
          task.status &&
          (task.status.toLowerCase() === 'in progress' ||
            task.status.toLowerCase() === 'inprogress')
        );
  
        const ToDo = combinedTasks.filter(
          (task) =>
            task &&
            task.status &&
            (task.status.toLowerCase() === 'todo' ||
              task.status.toLowerCase() === 'to do')
        );
  
        // Update the state after fetching and filtering the data
        setTodo(ToDo);
        setInprogress(InProgress);
        setDone(Done);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } catch (error) {
      console.error('Error fetching authorization tokens:', error);
    }
  };
  






  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceColumn = getColumnByTitle(source.droppableId);
    const destinationColumn = getColumnByTitle(destination.droppableId);

    const task = sourceColumn.tasks[source.index];

    task.status = destinationColumn.title;

    updateTaskStatus(task);
  };

  const getColumnByTitle = (title) => {
    switch (title) {
      case 'To Do':
        return { title: 'To Do', tasks: todo };
      case 'In Progress':
        return { title: 'In Progress', tasks: inprogress };
      case 'Done':
        return { title: 'Done', tasks: done };
      default:
        return null;
    }
  };
  
  const fetchTransitionId = async (status,key) => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHSTATUSFORSPECIFICJIRAISSUE,
        {
          Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
          Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
          key: key,
          Jira_URL: authorizationDetails.Jira_URL,
          status: status,
        },{headers:headers}
      );
  
      const statusData = response.data.body;
      const matchingStatus = statusData.find((s) => s.name === status);
  
      return matchingStatus ? matchingStatus.id : null;
    } catch (error) {
      console.error('Error fetching transitionId:', error);
      return null;
    }
  };
  
  
const updateTaskStatus = async (task) => {
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
  try {
    if (task.issue_key) {
      // If the task has an issue_key, call the first API
      const updateApiUrl = process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK;
      const updateApiPayload = {
        pod_task_id: task.pod_task_id,
        status: task.status,
      };
      
      // Make the API call to update the task's status
      await axios.put(updateApiUrl, updateApiPayload,{headers:headers});
    } else {
      // If the task does not have an issue_key, call the second set of APIs
      const transitionId = await fetchTransitionId(task.status, task.key);

      if (!transitionId) {
        console.error('Transition ID not found for status:', task.status);
        return;
      }

      const updatedPayload = {
        Jira_URL: authorizationDetails.Jira_URL,
        Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
        Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
        key: task.key,
        transitionId: transitionId,
        status: task.status,
      };

      // Make the API call to update the task's status
      await axios.put(process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE, updatedPayload,{headers:headers});
    }

    // Fetch the updated task list
    fetchAuthorizationTokensAndList();
  } catch (error) {
    console.error('Error updating task status:', error);
  }
};


  return (
    <div className="kanban-board">
      <h1>{intl.formatMessage({ id: 'podBoard' })}</h1>
      <Select
  options={podNames}
  value={podNames.find((option) => option.value === selectedPodId)}
  onChange={(selectedOption) => setSelectedPodId(selectedOption.value)}
  placeholder={intl.formatMessage({ id: 'selectPod' })}
  
  className="custom-width"
/>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="scrollable-board">
          <Droppable droppableId="To Do" type="TASK">
            {(provided, snapshot) => (
              <div
                className="column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="task-list">
                  <h6 style={{ color: "grey", fontWeight: "600" ,borderBottomColor:'#ff6666'  }}>{intl.formatMessage({ id: 'toDo' })} {todo.length}</h6>
                  {todo.length > 0 ? (
  todo.map((task, index) => (
    <Draggable
      key={task.key || index} // Use the 'key' property or provide a default value for the key
      draggableId={(task.key || index).toString()} // Use the 'key' property or provide a default value for the draggableId
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="task"
        >
          <div className="task-content">
            <div className="task-title">{task.summary || task.pod_task_name}</div>
            <div className="task-sprint">{task.key}</div>
          </div>
        </div>
      )}
    </Draggable>
  ))
) : (
  intl.formatMessage({ id: 'noData' })
)}


                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
  
          <Droppable droppableId="In Progress" type="TASK">
            {(provided, snapshot) => (
              <div
                className="column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="task-list">
                  <h6 style={{ color: "grey", fontWeight: "600" ,borderBottomColor:'yellow'  }}>{intl.formatMessage({ id: 'inProgress' })} {inprogress.length}</h6>
                  {inprogress.length > 0 ? (
  inprogress.map((task, index) => (
    <Draggable
      key={task.key || index} // Use the 'key' property or provide a default value for the key
      draggableId={(task.key || index).toString()} // Use the 'key' property or provide a default value for the draggableId
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="task"
        >
          <div className="task-content">
            <div className="task-title">{task.summary || task.pod_task_name}</div>
            <div className="task-sprint1">{task.key}</div>
          </div>
        </div>
      )}
    </Draggable>
  ))
) : (
  intl.formatMessage({ id: 'noData' })
)}

                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
  
          <Droppable droppableId="Done" type="TASK">
            {(provided, snapshot) => (
              <div
                className="column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="task-list">
                  <h6 style={{ color: "grey", fontWeight: "600",borderBottomColor:'green' }}>{intl.formatMessage({ id: 'Done' })} {done.length}</h6>
                  {done.length > 0 ? (
  done.map((task, index) => (
    <Draggable
      key={task.key || index} // Use the 'key' property or provide a default value for the key
      draggableId={(task.key || index).toString()} // Use the 'key' property or provide a default value for the draggableId
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="task"
        >
          <div className="task-content">
            <div className="task-title">{task.summary|| task.pod_task_name}</div>
            <div className="task-sprint2">{task.key}</div>
          </div>
        </div>
      )}
    </Draggable>
  ))
) : (
  intl.formatMessage({ id: 'noData' })
)}

                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
  
};

export default KanbanBoard;