import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Typography from '@mui/material/Typography';
import { useAuthToken } from "../TokenContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

const categories = ['Design', 'Development', 'Bug-Fixing', 'Testing', 'Performance', 'Security', 'Deployment'];

const hoursOptions = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const minutesOptions = ['05', '15', '30', '45', '55','60'];

const initialTimeEntry = {
  userStory: '',
  category: '',
  hours: '',
  minutes: '',
  podname: '',
};

const TimeSubmit = () => {
  const token = useAuthToken();

  const [podData, setPodData] = useState([]);
  const [timeEntries, setTimeEntries] = useState([initialTimeEntry]);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { podName, podid } = location.state;
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const addRow = () => {
    setTimeEntries([...timeEntries, { ...initialTimeEntry }]);
  };

  const removeRow = (index) => {
    const updatedEntries = [...timeEntries];
    updatedEntries.splice(index, 1);
    setTimeEntries(updatedEntries);
  };

  const handleDropdownChange = (field, value, index) => {
    const updatedEntries = [...timeEntries];
    updatedEntries[index][field] = value;
    setTimeEntries(updatedEntries);
  };

  const handleInputChange = (field, value, index) => {
    const updatedEntries = [...timeEntries];
    updatedEntries[index][field] = value;
    setTimeEntries(updatedEntries);
  };

  const [userStories, setUserStories] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        pod_id: podid || null,
        c_id: user.data[0].c_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && data.body) {
          const parsedBody = JSON.parse(data.body);
          if (Array.isArray(parsedBody.jiraIssues)) {
            setUserStories(parsedBody.jiraIssues);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [podid, user.data, token]);

  const handleSubmit = () => {
    const hasEmptyFields = timeEntries.some(
      (entry) => entry.userStory === '' || entry.category === ''
    );

    if (hasEmptyFields) {
      alert('Please select a User Story and Category for all entries.');
      return; // Prevent further processing
    }
    const payload = timeEntries.map((entry) => {
      const { userStory, category, hours, minutes, podname } = entry;
      const selectedUserStory = userStories.find((story) => story.issue_key === userStory);

      if (selectedUserStory) {
        return {
          userStory,
          category,
          time: hours + (minutes ? `:${minutes}` : ''),
          podname: podName,
          pod_id: podid,
          candidate_id: user.data[0].c_id,
          candidate_name: user.data[0].firstName,
          org_code: user.data[0].talent_code,
          jira_issue_id: selectedUserStory.issue_key,
          jira_summary: selectedUserStory.issue_summary,
          sprint_name: '',
          jira_project_id: '',
          workeddate: selectedDate.toISOString().split('T')[0],
          dayoftheweek: new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(selectedDate),
        };

      } else {
        console.error('Selected user story not found');
        return null;
      }

    });

    const validPayload = payload.filter((entry) => entry !== null);

    if (validPayload.length === 0) {
      console.error('All selected user stories were not found.');
      // Handle this error accordingly, e.g., show an error message to the user.
      return;
    }

    // console.log('Valid payload:', validPayload);

    fetch(process.env.REACT_APP_TALENT_JIRAISSUESTIMELOGFUNCTION, {
      method: 'POST',
      body: JSON.stringify(validPayload),
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // console.log('Server Response:', response);
        return response.json();
      })

      .then((data) => {
        // console.log('Response from the server:', data);
        alert('Submitted Time Entry Successfully');
        // history.push('/Pods');
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_TALENT_GETPODSFORTALENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ c_id: user.data[0].c_id || null }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && Array.isArray(data.body)) {
          setPodData(data.body);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.data, token]);

  return (
    <Div>
      <Breadcrumbs aria-label="breadcrumb" style={{ float: "left" }}>
        <Link to="/Pods" style={{ color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>My Pods</Link>
        <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>&nbsp;&gt;&nbsp;</span>
        <span style={{ color: 'grey', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600" }}>Time Sheet</span>
      </Breadcrumbs>
      <br />
      <br />
      <br />
      <TitleText>Pod TimeSheet</TitleText>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto' }}>
        <DateInput
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM-dd-yyyy"
          minDate={new Date()}
          todayButton="Today"
          className="styled-datepicker-input"
        />
        <span style={{ color: '#00B4D8', fontWeight: 'bold' }}>
          {selectedDate.toLocaleDateString()} ({new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(selectedDate)})
        </span>
      </div>
      <Button style={{ display: 'flex', marginLeft: 'auto', color: '#00B4D8' }} onClick={addRow}>
        <FaPlus style={{ color: '#00B4D8', marginLeft: 'auto' }} /> Add Row
      </Button>
      <TableContainer component={Paper}>
        <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: '#00B4D8',
              '& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight: '600',
              },
            }}
          >
            <TableRow>
              <TableCell>User Story</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Hours</TableCell>
              <TableCell>Minutes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeEntries.map((entry, index) => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                key={index}
              >
                <TableCell>
                  <Select
                    value={entry.userStory}
                    onChange={(e) => handleDropdownChange('userStory', e.target.value, index)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {userStories.map((story) => (
                      <MenuItem key={story.issue_key} value={story.issue_key}>
                        {story.issue_key}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={entry.category}
                    onChange={(e) => handleDropdownChange('category', e.target.value, index)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={entry.hours}
                    onChange={(e) => handleDropdownChange('hours', e.target.value, index)}
                  >
                    {hoursOptions.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={entry.minutes}
                    onChange={(e) => handleDropdownChange('minutes', e.target.value, index)}
                  >
                    {minutesOptions.map((minute) => (
                      <MenuItem key={minute} value={minute}>
                        {minute}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button onClick={() => removeRow(index)}>
                    <FaTrash />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={handleSubmit} style={{ marginTop: '20px' }} variant="contained" color="primary">
        Submit Time Entries
      </Button>
    </Div>
  );
};


const DateInput = styled(DatePicker)`
  border: 2px solid #90E0EF;
  border-radius: 5px;
  padding: 8px;
  font-family: 'Poppins,Medium';
  font-size: 15px;
  color: black;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const Div = styled.div`
display:inline;
justify-content:center;
align-items:center;
margin:auto;

  margin-top: 10rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-top: 10rem;
  }
`;

const TitleText = styled.h4`
  font-weight: 600;
`;
const Button3 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: Poppins, Medium;
  background-color: #00B4D8;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  height: 30px;
  width: max-width;
  padding: 5px;
  margin-top: 2rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 10rem;
    height: max-height;
    margin: auto;
  }
`;
export default TimeSubmit;