import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect ,useState} from 'react';
import axios from 'axios';
import Badge from "./Badge";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { NavHashLink } from 'react-router-hash-link';
import { CheckBox } from '@mui/icons-material';
import { useToastContainer } from 'react-toastify';
import AddPodactivityLead from './AddPodactivityLead';
import Pods from './Pods';
import { Calendar, DateRangePicker } from 'react-date-range';

import {RiDeleteBin6Line} from 'react-icons/ri';
import { FaSort } from 'react-icons/fa';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { IoAdd } from 'react-icons/io5';
import moment from 'moment';
import './stylereact.css';
import {  GrFilter } from "react-icons/gr";

import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import { jsPDF } from "jspdf";

import { FiDownload } from "react-icons/fi";
const ViewPodTasksManagerbyid = (props) => {
  const token = useAuthToken();
  const intl = useIntl();
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [blockerFilter, setBlockerFilter] = useState('');
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const { Id } = useLocation().state;
  const { podname } = useLocation().state;
  const [getpodactivitydata, setGetpodactivitydata] = useState([]);
  const [sorting, setSorting] = useState({
    column: '', // Default sorting column
    order: 'asc', // Default sorting order
  });
  const [isOpen, setIsOpen] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [filterOption, setFilterOption] = useState("");
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
    if (e.target.value === 'download') {
      setIsDownloadOpen(!isDownloadOpen);
      setShowDateRangePicker(false);
    } else if (e.target.value === 'dateRange') {
      setShowDateRangePicker(!showDateRangePicker);
      setIsDownloadOpen(false);
    } else {
      setIsDownloadOpen(false);
      setShowDateRangePicker(false);
    }
  };

  const handleDownloadChange = (e) => {
    handleSelection(e.target.value);
    setIsDownloadOpen(false);
  };
  
  const [showDownload, setShowDownload] = useState(false);
  
  useEffect(() => {
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
    const body = {
      pod_Id: Id,
    };
    axios.post(process.env.REACT_APP_CLIENT_CLIENTVIEWMYPODTASK, body, { headers: headers })
      .then((res) => {
        const sortedData = res.data.data.sort((a, b) => {
          return new Date(b.due_date) - new Date(a.due_date);
        });
        setGetpodactivitydata(sortedData);
        
        // Initialize date range to cover all data
        const minDueDate = new Date(Math.min(...sortedData.map(item => new Date(item.due_date))));
        const maxDueDate = new Date(Math.max(...sortedData.map(item => new Date(item.due_date))));
        setDateRange([{ startDate: minDueDate, endDate: maxDueDate, key: 'selection' }]);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, [token, Id]);
  

  const filterData = (data, selectedStatus, blockerFilter) => {
    let filteredData = data;
    if (selectedStatus) {
      filteredData = filteredData.filter((item) => item.status === selectedStatus);
    }
    if (blockerFilter === 'yes') {
      filteredData = filteredData.filter((item) => item.blockercheck === 1);
    } else if (blockerFilter === 'no') {
      filteredData = filteredData.filter((item) => item.blockercheck !== 1);
    }
    // Filter based on date range
    const startDate = dateRange[0].startDate;
    const endDate = dateRange[0].endDate;
    filteredData = filteredData.filter(item => {
      const dueDate = new Date(item.due_date);
      return dueDate >= startDate && dueDate <= endDate;
    });
    return filteredData;
  };
  
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

 
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getpodactivitydata.slice(indexOfFirstItem, indexOfLastItem);

  const filteredDataLength = filterData(currentItems, selectedStatus, blockerFilter).length;
const pages = [];
for (let i = 1; i <= Math.ceil(filteredDataLength / itemsPerPage); i++) {
  pages.push(i);
}


  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <div key={number}>
          <li id={number} onClick={handleClick} className={currentPage === number ? 'active' : null}>{number}</li>
        </div>
      );
    } else {
      return null;
    }
  });
  
  

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => {
      if (prevSorting.column === column) {
        // Toggle the sort order if the same column is clicked again
        return { ...prevSorting, order: prevSorting.order === 'asc' ? 'desc' : 'asc' };
      }
      // Set the new column and default sort order
      return { column, order: 'asc' };
    });
  };

  const sortedData = currentItems.sort((a, b) => {
    if (sorting.column === 'blockercheck') {
      if (sorting.order === 'asc') {
        return a.blockercheck - b.blockercheck;
      } else {
        return b.blockercheck - a.blockercheck;
      }
    } else if (sorting.column === 'due_date') {
      if (sorting.order === 'asc') {
        return new Date(a.due_date) - new Date(b.due_date);
      } else {
        return new Date(b.due_date) - new Date(a.due_date);
      }
    } else {
      if (sorting.order === 'asc') {
        return a[sorting.column] > b[sorting.column] ? 1 : -1;
      } else {
        return a[sorting.column] < b[sorting.column] ? 1 : -1;
      }
    }
  });

  const inlineStyles = {
    asc: {
      transform: 'rotate(180deg)'
    },
    desc: {
      transform: 'rotate(0deg)'
    }
  };
  const handleDownload = async (format) => {
    const filteredData = filterData(sortedData, selectedStatus, blockerFilter); // Define filteredData here
  
    if (format === "csv") {
      // Convert filteredData to downloadable format, for example, CSV
      let csvContent =
        "data:text/csv;charset=utf-8," +
        "Pod Name, Pod Task Name, Status, Candidate Name, Categories, Description, Jira Item, Blocker Check, Due Date\n";
  
      filteredData.forEach((item) => {
        // Construct each row of the CSV
        const row = `${podname},${item.pod_task_name},${item.status},${item.Candidate_Name},${item.category},${item.description},${item.jira_item},${item.blockercheck === 1 ? "Yes" : "No"},${moment(item.due_date).format("MM-DD-YYYY")}\n`;
  
        csvContent += row;
      });
  
      // Create a temporary link element and trigger the download
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "podtask_activity.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === "pdf") {
      if (filteredData.length > 0) {
        const doc = new jsPDF();
        let yPos = 10; // Initial y position
        filteredData.forEach((item) => {
          // Construct a string representing the item
          const itemString = `${podname}, ${item.pod_task_name}, ${item.status}, ${item.Candidate_Name}, ${item.category}, ${item.description}, ${item.jira_item}, ${item.blockercheck === 1 ? "Yes" : "No"}, ${moment(item.due_date).format("MM-DD-YYYY")}`;
          // Add the item to the PDF
          doc.text(itemString, 10, yPos);
          yPos += 10; // Increment y position for next item
          if (yPos >= 280) {
            // Check if nearing end of page
            doc.addPage(); // Add a new page if necessary
            yPos = 10; // Reset y position for new page
          }
        });
        doc.save("podtask_activity.pdf");
      } else {
        console.error("No data to generate PDF.");
      }
    }else if (format === "docx") {
      if (filteredData.length > 0) {
        let docContent = `
          <html>
            <body>
              <table>
                <tr>
                  <th>Pod Name</th>
                  <th>Pod Task Name</th>
                  <th>Status</th>
                  <th>Candidate Name</th>
                  <th>Categories</th>
                  <th>Description</th>
                  <th>Jira Item</th>
                  <th>Blocker Check</th>
                  <th>Due Date</th>
                </tr>
        `;
  
        filteredData.forEach((item) => {
          docContent += `
            <tr>
              <td>${podname}</td>
              <td>${item.pod_task_name}</td>
              <td>${item.status}</td>
              <td>${item.Candidate_Name}</td>
              <td>${item.category}</td>
              <td>${item.description}</td>
              <td>${item.jira_item}</td>
              <td>${item.blockercheck === 1 ? "Yes" : "No"}</td>
              <td>${moment(item.due_date).format("MM-DD-YYYY")}</td>
            </tr>
          `;
        });
  
        docContent += `
              </table>
            </body>
          </html>
        `;
  
        const blob = new Blob([docContent], { type: "application/msword" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "podtask_activity.docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }  else {
        console.error("No data to generate Word document.");
      }
    }
  };
  
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSelection = (format) => {
    handleDownload(format);
    setIsOpen(false); // Close the dropdown after selection
  };
  
  const renderdata = (data) => {
    return (
      <Div>
        <TitleText>{intl.formatMessage({ id: 'podTasks' })}</TitleText>
        <div style={{ display: 'flex', marginLeft: 'auto', width: '15rem' }}>
        <div style={{ position: 'relative' }}>
          <select
            style={{ width: '15rem', border: 'none', textDecoration: 'none' }}
            onChange={handleFilterChange}
            value={filterOption}
          >
            <option value="">Filter Options</option>
            <option value="download">Download</option>
            <option value="dateRange">Date Range Filter</option>
          </select>
          {isDownloadOpen && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                zIndex: '1',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                marginTop: '5px'
              }}
            >
              <select
                style={{ width: '15rem', border: 'none', textDecoration: 'none' }}
                onChange={handleDownloadChange}
              >
                <option value="">Download as...</option>
                <option value="csv">CSV</option>
                <option value="pdf">PDF</option>
                <option value="docx">DOCX</option>
              </select>
            </div>
          )}
        </div>
      </div>
      {filterOption === 'dateRange' && showDateRangePicker && (
        <DateRangePicker
          onChange={(item) => setDateRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateRange}
          direction='horizontal'
        />
      )}
        <ol className='pageNumbers'>
          <Num2>
            <li>
              <button onClick={handlePrevbtn} disabled={currentPage === pages[0] ? true : false}>
                {intl.formatMessage({ id: 'prev' })}
              </button>
            </li>
            <Numbers>{renderPageNumbers}</Numbers>
            <li>
              <button onClick={handleNextbtn} disabled={currentPage === pages[pages.length - 1] ? true : false}>
                {intl.formatMessage({ id: 'next' })}
              </button>
            </li>
          </Num2>
        </ol>
        {getpodactivitydata && getpodactivitydata.length === 0 ? (
          <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
            {intl.formatMessage({ id: 'noPodTasks' })}...
          </CardContent>
        ) : (
          <>
            <TableContainer component={Paper}>
              <div>
                <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
                  <TableHead sx={{
                    backgroundColor: '#00B4D8', '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'white',
                      fontWeight: '600'
                    },
                  }}>
                    <TableRow>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podName' })}</TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'podTask' })}</TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>
                        <SelectField value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                          <option value="">All Status</option>
                          <option value="completed">Completed</option>
                          <option value="Pending">Pending</option>
                        </SelectField>
                      </TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'PodTeamMember' })}</TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'categories' })}</TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'description' })}</TableCell>
                      <TableCell align="right"  sx={{ fontWeight: 'bold' }}>Jira Item</TableCell>
                      <TableCell align="right" >
                        <SelectField value={blockerFilter} onChange={(e) => setBlockerFilter(e.target.value)}>
                          <option value="">All Blockers</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </SelectField>
                      </TableCell>

                      <TableCell align="right"  sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('due_date')}>
                        {intl.formatMessage({ id: 'dueDate' })} <span style={{ fontSize: '10px', marginLeft: '1px' }}><FaSort /></span>
                        {sorting.column === 'due_date' && (
                          <AiOutlineSortAscending style={sorting.order === 'asc' ? inlineStyles.asc : inlineStyles.desc} />
                        )}
                      </TableCell>
                    </TableRow>
                    
                  </TableHead>
                  <TableBody>
                    {filterData(sortedData, selectedStatus, blockerFilter).map((x1) => (
                      <TableRow key={x1.pod_task_id} sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: '#caf0f8' },
                        '& td, & th': { borderColor: 'rgba(224, 224, 224, 1)', borderWidth: '1px', borderStyle: 'solid', padding: '10px' },
                        '& th': { fontWeight: 'bold', fontSize: '15px' },
                        '& td': { fontFamily: 'Poppins,Medium', fontSize: '15px' },
                        '&:nth-child(even)': { backgroundColor: '#90E0EF' },
                        '&:nth-child(odd)': { backgroundColor: '#caf0f8' },
                        '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'black' },
                      }}>
                        <TableCell align="right">{podname}</TableCell>
                        <TableCell align="right">{x1.pod_task_name}</TableCell>
                        <TableCell align="right">{x1.status}</TableCell>
                        <TableCell align="right">{x1.Candidate_Name}</TableCell>
                        <TableCell align="right">{x1.category}</TableCell>
                        <TableCell align="right">{x1.description}</TableCell>
                        <TableCell align="right">{x1.jira_item}</TableCell>
                        <TableCell align="right">
                          {x1.blockercheck === 1 ? (
                            <CheckBox checked disabled />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell align="right">{moment(x1.due_date).format("MM-DD-YYYY")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </>
        )}
      </Div>
    );
  };

  return (
    <div>
      {renderdata(currentItems)}
    </div>
  );
};

export default ViewPodTasksManagerbyid;



const Div =styled.div`
margin-left: 5rem;
margin-top: 10rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
     width: 80vw;
  margin-left:4rem;
  }

`;
const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin:auto;
    width: 80vw;
  margin-left:4rem;
  }
`;
const ButtonEdit =styled.button`

  text-decoration: none  !important;

  background:none;
 
 
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const ButtonAdd =styled.button`
display: flex;
    flex-direction: row;
justify-content: center;
align-items: center;

    margin: auto;
  text-decoration: none  !important;
  border-radius: 10px;
 font-weight:600;
 background:none;
  color: #00B4D8;
  border: none  !important;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Para =styled.p`
width: max-width;
float: right;
padding: 5px;
color: white;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin: auto;
  }

`;
const Button=styled.button`
display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
    margin: auto;
margin-top:1rem;

border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:5rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;

@media screen and (min-width:320px) and (max-width:1080px){
  width:5rem;
 
  
 }
`;
const Buttondelete =styled.button`

  text-decoration: none  !important;
 
  background:none  !important;

  color: black;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const Buttonpost =styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:seagreen;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
        const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;
        const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Span=styled.span`
cursor:pointer;
`;

const SelectField = styled.select`
 
  border-radius: 5px;
  width: 9.5rem;
  height: 3rem;
  font-weight: 600;
  color: white;
  font-family: Poppins, Medium;
background-color:#00B4D8;
border:none;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    margin: auto;
  }
`;