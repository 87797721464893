import styled from "styled-components";
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import { data, error, getJSON } from "jquery";
import { Route } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import EditPostRequirementCand from './EditPostRequirementCand';
import { useParams, useHistory } from 'react-router-dom';
import PostRequirementCand from "./PostRequirementCand";
import Navbar from "../Dashboard/Navbar";
import{FiSearch} from 'react-icons/fi';
import ManagePod from "./ManagePod";
import defaultimg from './assets/defaultimge.png';
import './stylereact.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Collapse } from '@mui/material';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import {GrFormEdit} from 'react-icons/gr';
import { useTable, usePagination } from 'react-table';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 

import ViewPostedProReq from './ViewPostedProReq';
import { DateRangePicker } from 'react-date-range';
import { pink } from "@mui/material/colors";
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const Podretromanager = () => {

  const token = useAuthToken();

  const intl = useIntl();


  const { isLoggedIn, user } = useSelector(state => state.auth);
 
  const [getpoddetails, setGetpoddetails] = useState([]);
  const [getcandidates, setGetcandidates] = useState([]);
  const [getactivitiy, setGetactivitiy] = useState([]);
  const[activities,SetActivities]=useState([]);
  const[allactivities,SetAllactivities]=useState([]);
  const [getfilteractivitiy, setFilterGetactivitiy] = useState([]);
const[startDate,setStartDate]=useState(new Date());
const[endDate,setEndDate]=useState(new Date());
  const start_date=useRef(new Date())
  const end_date=useRef(new Date());
  const [selectedPod,setSelectedPod]=useState("");
  const [podskill, setPodskill] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [optionValue, setOptionValue] = useState(0);
  const params = useParams();
  const [selectedIds, setSelectedIds] = useState([]);
  const [objects, setObjects] = useState([]);


  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);

  const [currentPage,setCurrentPage] = useState(1);
  const recordsPerPage = 2;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  
  const records = getfilteractivitiy.slice(firstIndex,lastIndex);
  // console.log("records",records)
  const npages = Math.ceil(getfilteractivitiy.length / recordsPerPage);
  const numbers = [...Array(npages+1).keys()].slice(1);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };


  const options = [
    'None',
    'date'
  ];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setOptionValue(event.target.value)
    setAnchorEl(null);
  };
  const filterFunction= (e)=>{
    setCurrentPage(1)
    
     
     let local_filter_date=getactivitiy;                  
     let filter_output = local_filter_date.filter((item)=> (start_date.current <= new Date(moment(item.createdAt).format("DD-MM-YYYY")) && end_date.current >= new Date(moment(item.createdAt).format("DD-MM-YYYY"))) )
     
     setFilterGetactivitiy(filter_output)

  }



  useEffect(() => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  
    const body = {
      "clientID": user.data[0].emp_id
    };
    // const body = {
    //   "clientID": 1542
    // };


    
    // https://7rafqh7833.execute-api.us-east-1.amazonaws.com/dev/getclientpod
    axios.post(process.env.REACT_APP_CLIENT_GETCLIENTPOD, body,{headers:headers})

      .then((res) => {
        SetActivities(res.data.data.activities)
        SetAllactivities(res.data.data.activities)
            // console.log(res.data.data.podDetails)
            // console.log(res.data.data)
            if(res.data !=  undefined ){
              if(res.data.data != undefined){
                if(res.data.data.candidates != undefined){
                  setGetcandidates(res.data.data.candidates)
                }
                else{
                  setGetcandidates([""])
                }
                if(res.data.data.podDetails != undefined){
                  setGetpoddetails(res.data.data.podDetails)
                  if(res.data.data.podDetails.skills != undefined){
                    setPodskill(JSON.parse(res.data.data.podDetails.skills))
                  }
                  else{
                    setPodskill([""])
                  }
                }
                else{
                  // setGetpoddetails({""})
                }
                if(res.data.data.activities != undefined){
                  setGetactivitiy(res.data.data.activities)
                 
                  setFilterGetactivitiy(res.data.data.activities)

                }
                else{
                  setGetactivitiy([""])
                  setFilterGetactivitiy([""])
                }
                
              }

            }
        
        
        
        
      }

      )
      .catch((err) => {
        console.log("catch", err);
      })


  }, [token]);

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleSubmit = () => {
    const ids = selectedIds.map((id) => id); // Copy selectedIds array
  
    const payload = {
      poddata: [{ id: ids, manager_id: user.data[0].emp_id }],
      manager_id: user.data[0].emp_id,
    };
  
    fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization' : token,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
      
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };
  

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {

    // const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    // const interval = intervals.find(i => i.seconds < seconds);
    // const count = Math.floor(seconds / interval.seconds);
    // return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;


  }

  const prePage =()=>{
    if(currentPage !== 1){
      setCurrentPage(currentPage-1)
    }
  }
  const nextPage=()=>{
    if(currentPage !== npages ){
      setCurrentPage(currentPage+1)
    }
  }
  const changeCPage=(id)=>{
    setCurrentPage(id)
  }


  const handleSelect=(date)=>{
    let filtered=allactivities.filter((getfilteractivitiy)=>{
let activitiesDate = new Date(getfilteractivitiy['createdAt']);
return ( 
  activitiesDate >= date.selection.startDate &&
 activitiesDate <= date.selection.endDate 
 );
});
    setStartDate(date.selection.startDate)
    setEndDate(date.selection.endDate)
    setFilterGetactivitiy(filtered)
    console.log(date.selection.startDate)
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
    
  }
;

const datePickerStyles = {
  
    backgroundColor: '#caf0f8', // Change the background color here
   
  }

const rangeColors = ['#00B4D8'];


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const [poddataretro, setinputListretro] = useState(
    {retro: '', sprint: '', pods_id: '' ,start_doing:'',stop_doing:'' ,keep_doing:''});
   

    const [sprints, setSprints] = useState([]); // State variable to hold sprint options
    const [selectedSprint, setSelectedSprint] = useState(''); // State variable to hold the selected sprint
    const [authorizationDetails, setAuthorizationDetails] = useState({});
    useEffect((token) => {



      
      fetchAuthorizationTokensAndList();
    }, [token]); // Run this effect only once when the component mounts
    
    const fetchAuthorizationTokensAndList = async () => {
      try {
        
    
        // Fetch authorization token
        const authorizationPayload = {
          Organizational_Code: user.data[0].client_code,
        };

        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };
    
        const authorizationResponse = await axios.post(
          //https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/fetch_authorization_tokens
   process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          authorizationPayload, {headers:headers}
        );
    
        const authorizationData = authorizationResponse.data.body.data[0];
    
      // Store authorization details in state
      setAuthorizationDetails(authorizationData);
        // Extract the necessary token and URLs from the authorization response
        const jiraUrl = authorizationData.Jira_URL;
        const jiraAdminEmail = authorizationData.Jira_admin_login_email_address;
        const jiraAdminToken = authorizationData.Jira_admin_API_Token;
        const githubUsername = authorizationData.Github_username;
        const githubApiToken = authorizationData.Github_API_Token;
        const projectKey=authorizationData.Jira_Kanben_Project_name
        // ... Extract other token and URLs as needed
       
      
  
        // Construct the payload for the list API using the obtained data
       //'https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/fetch_sprint_numbers'
    
        fetch(process.env.REACT_APP_CLIENT_FETCHSPRINTNUMBERS, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token, 
          },
          body: JSON.stringify({
            Jira_URL:jiraUrl,
            Jira_admin_login_email_address:jiraAdminEmail,
            Jira_admin_API_Token: jiraAdminToken
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("sprints", data.body);
            if (data.body) {
              const parsedData = JSON.parse(data.body); // Parse the JSON string in data.body
              if (parsedData.sprints) {
                setSprints(parsedData.sprints); // Set the fetched sprint options in state
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching sprint options:', error);
          });
      } catch (error) {
        console.error('Error fetching authorization token:', error);
      }
    };
  

  
    const onInputChange = (event) => {
        const { id, value } = event.target;
     
      };

const onInputChangesprint = (event) => {
    
        setSelectedSprint(event.target.value);
        // Update your state or manage the value in your component
      };
      const [retrolisting, setRetrolisting] = useState([]);
      const [currentPageretro, setCurrentPageretro] = useState(1);
      const itemsPerPageretro = 4; // Number of items per page
      // const hasMorePages = retrolisting.length === itemsPerPageretro; // Assume there are more pages if the number of fetched items equals itemsPerPage
      
      const [selectedPodID, setSelectedPodID] = useState(null); // Initialize with null or some default value
    

      const handleSubmitpodretro = (e, PodID) => {
        e.preventDefault();
      
        const retroFeedback = {
          start_doing: e.target.start_doing.value,
          stop_doing: e.target.stop_doing.value,
          keep_doing: e.target.keep_doing.value,
        };
      
        const formData = {
          retro: retroFeedback,
          sprint: 1,
        status:"Completed",
          submitted_by: user.data[0].emp_firstname || null,
          submitted_by_id: user.data[0].emp_id,
          submitted_date: new Date().toISOString(), // Set to the current date and time
        };
      console.log('check',formData)
        const requestData = {
          pods_id: PodID,
          ...formData,
        };
      
        const requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestData),
        };
      
        fetch(process.env.REACT_APP_TALENT_PODRETROS, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 400) {
              alert(data.data);
            } else if (data.status === 200) {
              alert("Posted Successfully.");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
              setSelectedPodID(PodID);
            } else {
              console.log("error");
            }
          });
      };
      
      

      const getRetroFields = (retroString) => {
        try {
          return JSON.parse(retroString) || {};
        } catch (error) {
          console.error('Error parsing retro:', error);
          return {};
        }
      };
        const fetchretro = async (PodID) => {
          const headers = {
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          };
          try {
            //'https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/pod_retro_list'
            const response = await axios.post(process.env.REACT_APP_CLIENT_PODRETROLIST, {
              pods_id: PodID,
            },{headers:headers});
      
            const responseData = response.data.data;
            const processedData = responseData.map(item => ({
              ...item,
              retroFields: getRetroFields(item.retro),
            }));
      
            // console.log(responseData);
            setRetrolisting(processedData);
          } catch (error) {
            console.error('Error fetching candidates:', error);
          }
        };
    
        useEffect((token) => {
          if (selectedPodID !== null) {
            fetchretro(selectedPodID);
          }
        }, [selectedPodID,token]);
        

        const handlePageChangeretro = (newPage) => {
          // fetchretro(PodID); // No need to send page as a parameter
          setCurrentPageretro(newPage);

        };
        const totalPages = Math.ceil(retrolisting.length / itemsPerPageretro);

        // Calculate the startIndex and endIndex based on currentPage
        const startIndex = (currentPageretro - 1) * itemsPerPageretro;
        const endIndex = Math.min(startIndex + itemsPerPageretro, retrolisting.length);
        
        // Get the items for the current page
        const itemsOnPage = retrolisting.slice(startIndex, endIndex);
        
  return (
    
    <>
    
    <Div>


        
      <TitleText>{intl.formatMessage({ id: 'podRetro' })}</TitleText>
      
      
     
      <RecommendProject>
      

{Object.keys(getpoddetails).length == 0 ? (<><p>{intl.formatMessage({ id: 'noPods' })}</p>  <br></br>
</> ) : getpoddetails.map((result)=>(<>
  <CardContent>
      <div>
      
           {/* <Button className="btn" onClick={()=>setOpen(!open)}> */}
           <Row className="btn" onClick={()=>{
            setOpen(!open)
            if(selectedPod != result.podName){
              setSelectedPod(result.podName)
              setSelectedPodID(result.id); 
              let local_filter_podid=getactivitiy;                  
              let filter_output = local_filter_podid.filter((item)=> item.pod_id == result.id )
              setFilterGetactivitiy(filter_output)
              fetchretro(result.id); 
            } 
            else{
              setSelectedPod("")
            }
            
            }}>
            <Detail>

              <InfoContainer>

                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 'auto' }}
                  
                ></img>
                <InfoPod>
                  <InfoName>{result.podName}</InfoName>
                 
                  {/* <InfoUpdate> {timeSince(new Date(item.createdAt))} </InfoUpdate> */}
                </InfoPod>
                
             
                
              </InfoContainer>
            </Detail>
           </Row>
           {/* </Button> */}

           <Collapse in={(selectedPod == result.podName)?true:false}>
               <div>
               <Poddetails >
               <form onSubmit={(e) => handleSubmitpodretro(e, result.id)}>








<Row1>
  <Label>{intl.formatMessage({ id: 'podRetro' })} :</Label>
            <Textarea
              id="retro"
              required

              onChange={onInputChange}
              placeholder="Pod Retro Feedback" />


</Row1>

<Row1>
        <Label>{intl.formatMessage({ id: 'sprint' })}  :</Label>
        <SelectField
  id="sprint"
  onChange={onInputChangesprint}
  value={selectedSprint}
>
  <option value="" disabled>Select Sprint</option>
  {sprints.map((sprint) => (
    <option key={sprint} value={sprint}>
      {sprint}
    </option>
  ))}
</SelectField>

      </Row1>

      <Row1>
  <Label>{intl.formatMessage({ id: 'startDoing' })}  :</Label>
            <Textarea
              id="start_doing"
              required

              onChange={onInputChange}
              placeholder="Start Doing" />


</Row1>
<Row1>
  <Label>{intl.formatMessage({ id: 'stopDoing' })}  :</Label>
            <Textarea
              id="stop_doing"
              required

              onChange={onInputChange}
              placeholder="Stop Doing" />


</Row1>
<Row1>
  <Label>{intl.formatMessage({ id: 'keepDoing' })}  :</Label>
            <Textarea
              id="keep_doing"
              required

              onChange={onInputChange}
              placeholder="Keep Doing" />


</Row1>

     


            <div style={{ marginLeft: 'auto*' }}>
              {/* <NavLink  to ='/VIewDailyPostedUpdates' > */}
              <Button type='submit'>
              {intl.formatMessage({ id: 'post' })} 
              </Button>

              {/* </NavLink> */}
            </div>


          </form>

          
               </Poddetails>
               </div>
           </Collapse>
        </div>

        </CardContent>
        
        <Collapse in={(selectedPod == result.podName)?true:false}>
          <TitleText></TitleText>
          {tableView && (selectedPod == result.podName) && <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            style={{float:'right'}}
            
          >
            <MoreVertIcon />
          </IconButton>}
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={opens}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option,i) => (
              <MenuItem key={option} value={i} selected={option === 'None'} onClick={handleClose}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          {optionValue==1 && <>
            <input type="date" name="start_date" onChange={(e)=>{
                let value=new Date(e.target.value)
                start_date.current=value;
                filterFunction();
              
              }} />
            <input type="date" name="end_date" onChange={(e)=>{
              
                let value=new Date(e.target.value)
                end_date.current=value;
                filterFunction()

              }} />
            <IconButton onClick={()=>{
              setOptionValue(0);
              setFilterGetactivitiy(getactivitiy);
              
              setCurrentPage(1)
              }}>
                          <CloseIcon />
            </IconButton>
            </>}
           

            {/* <Buttonexp onClick={toggleExpand}>
        {isExpanded ? 'Date Range Filter' : 'Date Range Filter'}
      </Buttonexp>
      {isExpanded && <div>  <DateRangePicker 
            style={datePickerStyles}
            rangeColors={rangeColors}
           showMonthAndYearPickers
        ranges={[selectionRange]}
        onChange={handleSelect}
      /></div>}
           */}
          <Div1>

           {tableView && (selectedPod == result.podName) && <TableContainer component={Paper} sx={{marginTop: '2rem'}}>
            <Table component={Paper} sx={{      maxWidth: { xs: 400, md: 1000 } ,backgroundColor:'#90E0EF',fontFamily:'Poppins,Medium', borderRadius: '10px',}}aria-label="simple table">
              <TableHead     sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}}>
                <TableRow    > 
        
                {/* <TableCell align="right">Select</TableCell> */}
                <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'teamMember' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'date' })}</TableCell>
                  <TableCell align="right">{intl.formatMessage({ id: 'podFeedback' })}</TableCell>
                 
                 
                 
                  <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'sprint' })}</TableCell>
                  <TableCell align="right">{intl.formatMessage({ id: 'startDoing' })}</TableCell>
      <TableCell align="right">{intl.formatMessage({ id: 'stopDoing' })}</TableCell>
      <TableCell align="right">{intl.formatMessage({ id: 'keepDoing' })}</TableCell>
                
                </TableRow>
              </TableHead>
           
              {/* records tulasi*/}  
              <TableBody>
      {itemsOnPage.length > 0 ? itemsOnPage.map((item) => 
    <TableRow key={item.sprint_id}>
      <TableCell align="right">{item.submitted_by}</TableCell>
      <TableCell align="right">  { moment(item.createdAt).format("MM-DD-YYYY")}</TableCell>
    
     
      
      <TableCell align="right">{item.sprint}</TableCell>
    <TableCell align="right">{item.retroFields.start_doing}</TableCell>
      <TableCell align="right">{item.retroFields.stop_doing}</TableCell>
      <TableCell align="right">{item.retroFields.keep_doing}</TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell colSpan={2} align="center">
      {intl.formatMessage({ id: 'noReviews' })}
      </TableCell>
    </TableRow>
  )}
</TableBody>
{/* Pagination */}
<TableFooter>
  <TableRow>
    <TablePagination
      rowsPerPageOptions={[]}
      colSpan={2} // Update this to match the number of table cells you have per row
      count={retrolisting.length}
      rowsPerPage={itemsPerPageretro}
      page={currentPageretro - 1}
      onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
      ActionsComponent={(props) => (
        <TablePaginationActions
          {...props}
          count={retrolisting.length}
          page={currentPageretro - 1}
          onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
        />
      )}
    />
  </TableRow>
</TableFooter>

                
            </Table>
            {/* <Button onClick={handleSubmit}>Approve</Button> */}
                  
            </TableContainer>} 
            </Div1>
      </Collapse>

      {(selectedPod == result.podName) && <hr />}
          
          </>))}


          {/* </Divseperator> */}

          
       



        


      </RecommendProject>
    </Div>
    
    </>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
margin-top:5rem;
  align-items: center;
margin:auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100vw;
   
  }
`;
const Div1 = styled.div`
  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
  width: 70vw;
  margin-left:4rem;
  }
`;

const ButtonEdit = styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject = styled.div`

 display:flex;

jutify-content:center;
flex-direction:column;
@media screen and (min-width:320px) and (max-width:1080px){
  
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`

width:5rem;
font-size:15px;
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:9rem;
  font-size:15px;

  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const Answer1 = styled.p`
  font-weight: 600;
  width:16rem;
  font-size:15px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;


const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    
    margin:auto;
     width: 70vw;
    margin-left:4rem;
  }
`;
const Divseperator = styled.div`
// border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableInfoContainer = styled.div`
  display: block;
  align-items: left;
  justify-content: left;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const TableInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 0px;
`;
const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`

  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard = styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:34rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:20rem;

  margin: auto;
  margin-top:2rem;
}
`;



const SideCardContent = styled.div`
background-color:#90E0EF;
width:17rem;

display:flex;
flex-direction:column;
padding:3px;
margin:1rem;

border-radius:20px;
`;


const SideCardContent1 = styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1 = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll = styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View = styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;


const Poddetails = styled.div`
display:flex;
flex-direction:row;
@media screen and (min-width: 320px) and (max-width: 1080px) {
  flex-direction: column;
  justify-content: flex-start;
 
}
`;



const PodDiv  = styled.div`

`;

const Candidatesdetails  = styled.div`
height:12rem;
padding:5px;
width:14rem;
border-radius:15px;
height:20rem;
background-color:rgb(166, 194, 206,30%);
overflow-y: hidden;
overflow-x: hidden;
margin-top:2rem;
@media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;

  }
`;

const Colum  = styled.div`

margin-left:10px;
`;


const PositionCand = styled.p`
  font-weight: 600;
 width:max-content;
 width:11rem;
  font-size:15px;
margin-top:-1rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const PositionCand1 = styled.p`
  font-weight: 600;
 width:11rem;
  font-size:15px;
margin-top:5px;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {

  }

  `;

  const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;


const Button=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:10rem;
height:2rem;
font-size:15px;
font-weight:600;
color:white;

`;
const Buttonexp=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#00B4D8;
box-shadow: 1px 3px 6px #00000029;
width:12rem;
height:2rem;
font-size:15px;
font-weight:600;
color:white;

`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-top:-2rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
    margin-top:2rem;
 margin-bottom:2rem;
 width: 18rem;
}
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
width:25rem;


tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const ManageButton=styled.button`
display:flex;
justify-content:center;
align-items:center;
// margin-left:15rem;
padding:5px;
width:10rem;
height:2rem;
font-size:16px;
font-weight:600;
color:white;

border:none;
border-radius:5px  ;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;




`;


const Label =styled.label`
margin-top:1rem;

font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:10rem;
`;
const Textarea = styled.textarea`
width:25rem;


tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const SelectField=styled.select`
margin-top:10px;
border: none;
text-decoration:none;
width:25rem;
height:3.1rem;
font-weight:600;

// background:none !important;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
margin:auto;
  
 }
`;


export default Podretromanager
