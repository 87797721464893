import React, { Component, useEffect, useState } from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory ,useLocation} from "react-router-dom";
import Navbar from "./Navbar";
import { login } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import PersonIcon from '@mui/icons-material/Person';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import S3FileUpload from 'react-s3';
import ResumeUploadPage from "./ResumeUploadPage";
import { v4 as uuidv4 } from 'uuid';  // Install uuid using npm if you haven't already
import styled from 'styled-components';
import AWS from 'aws-sdk';
import Loader from './Loader'; // Import the Loader component
import CreateProfilePortfolio from "./CreateprofilePortfolio";


const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const area = [
  {
    value: 'Node', label: 'Node'
  },
  {
    value: 'Java', label: 'Java'
  },
  {
    value: 'React', label: 'React'
  },
  {
    value: 'Python', label: 'Python'
  },
  {
    value: 'AWS', label: 'AWS'
  },
  {
    value: 'C++', label: 'C++'
  }
]

//for country
const country = [
  {
    value: 'usa', label: 'USA'
  },
  {
    value: 'canada', label: 'Canada'
  },
  {
    value: 'india', label: 'India'
  }
]


const techStack = [
  {
    value: 'MEAN', label: 'MEAN'
  },
  {
    value: 'MERN', label: 'MERN'
  },
  {
    value: 'LAMP', label: 'LAMP'
  },
  {
    value: 'MEVN', label: 'MEVN'
  }
]
//new
const industry = [
  {
    value: ' Healthcare', label: ' Healthcare'
  },
  {
    value: 'IT & Software', label: 'IT & Software'
  },
  {
    value: ' Banking and Financial', label: ' Banking and Financial'
  },
  {
    value: 'Services', label: 'Services'
  }
]

const jobTitleOptions = [
  { value: 'UI/UX Designer', label: 'UI/UX Designer' },
  { value: 'Software Engineer', label: 'Software Engineer' },
  { value: 'Full-Stack Developer', label: 'Full-Stack Developer' },
  { value: 'Front-End Developer', label: 'Front-End Developer' },
  { value: 'Back-End Developer', label: 'Back-End Developer' },
  { value: 'Mobile Developer', label: 'Mobile Developer' },
  { value: 'QA Engineer', label: 'QA Engineer' },
  { value: 'Scrum Master', label: 'Scrum Master' },
  { value: 'Product Owner', label: 'Product Owner' },
  { value: 'Product Manager', label: 'Product Manager' },
  { value: 'Project Manager', label: 'Project Manager' }
];
const secondarea = [
  {
    value: 'Angular', label: 'Angular'
  },
  {
    value: 'Java', label: 'Java'
  },
  {
    value: 'React', label: 'React'
  },
  {
    value: 'Python', label: 'Python'
  },
  {
    value: 'AWS', label: 'AWS'
  },
  {
    value: 'C++', label: 'C++'
  }
]

const s3 = new AWS.S3(S3Config);

const CreateProfile = () => {
 const [isLoading, setIsLoading] = useState(false); // State to manage loading state
 
  const location = useLocation();
  // const { resumeData } = location.state || {};
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [resumeData, setResumeData] = useState(null);
  const [projectsData, setProjectsData] = useState(null);
  const token = useAuthToken();
  const intl = useIntl();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');



  const [portfolioItems, setPortfolioItems] = useState([
    {
      projectObjective: '',
      projectDescription: '',
      industry: '',
      images: [],
    },
  ]);

  // Prefill the form with response data when the component mounts
  useEffect(() => {
    if (projectsData?.body?.projects?.length > 0) {
      const prefilledItems = projectsData.body.projects.map((project) => ({
        projectObjective: project.project_objective || '',
        projectDescription: project.project_description || '',
        industry: project.industry || '',
        images: [],
      }));
      setPortfolioItems(prefilledItems);
    }
  }, [projectsData]);

  const handleInputChangeportfolio = (e, index) => {
    const { name, value } = e.target;
    setPortfolioItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };

  const handleImageUpload = (e, index) => {
    const files = Array.from(e.target.files);
    if (files.length > 3) {
      alert('You can upload up to 3 images only.');
      return;
    }

    setPortfolioItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, images: files } : item
      )
    );
  };

  const handleAddItem = () => {
    setPortfolioItems((prevItems) => [
      ...prevItems,
      {
        projectObjective: '',
        projectDescription: '',
        industry: '',
        images: [],
      },
    ]);
  };
  const handleRemoveItem = (index) => {
    setPortfolioItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

AWS.config.update({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
});

const s3 = new AWS.S3();
  const uploadImageToS3 = async (imageFile) => {
    const imageKey = `portfolio-images/${uuidv4()}.png`;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKETNAME,
      Key: imageKey,
      Body: imageFile,
      ContentType: 'image/png',
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      return uploadResult.Location;
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      throw error;
    }
  };
  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setState({ profileImg: reader.result })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  const [formData, setFormData] = useState({
    education: [],
    certifications: [],
    exp: [],
});


// useEffect(() => {
//   if (location.state && location.state.resumeData) {
//     setFormData(location.state.resumeData);
//   }
// }, [location.state.resumeData]);


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      return;
    }
    setIsLoading(true); // Start loading

    const fileName = selectedFile.name;
    const bucketName = process.env.REACT_APP_BUCKETNAME;

    const uploadParams = {
      Bucket: bucketName,
      Key: fileName,
      Body: selectedFile,
      ContentType: selectedFile.type,
    };

    try {
      
      const data = await s3.upload(uploadParams).promise();
      console.log('File uploaded successfully:', data.Location);
  
      // Update the form state with the resume key
      setState((prevState) => ({
        ...prevState,
        resume: data.Key, // Use the key from the S3 upload response
      }));
      const response = await fetch(process.env.REACT_APP_TALENT_RESUMEPARSAR, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file_name: fileName,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        setResumeData(responseData?.body);
        setFormData(responseData?.body)
setProjectsData(responseData)
        
      } else {
        setUploadStatus('Failed to upload the resume. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('Error uploading file. Please try again.');
    }
    finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleSelectChange = (e, section, index, field) => {
    const value = e.target.value;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      updatedData[section][index][field] = value;
      return updatedData;
    });
  };

 

  // Function to handle adding a new item to a section
  const handleAddSectionItem = (section) => {
    setFormData(prevData => {
      const updatedData = { ...prevData };
    
      if (!updatedData[section]) {
        updatedData[section] = [];
      }
    
      switch (section) {
        case 'education':
          updatedData.education.push({
            degree: '',
            domain: '',
            institution: '',
            startDate: '',
            endDate: '',
            candidateID: user.data[0].c_id // Ensure candidateID is included if needed
            //id: Date.now() 
          });
          break;
    
        case 'certifications':
          updatedData.certifications.push({
            cert_name: '',
            certificationInstitution: '',
            certUrl: '',
            startDate: '',
            endDate: '',
            candidateID: user.data[0].c_id
            //id: Date.now()
          });
          break;
    
        case 'exp':
          updatedData.exp.push({
            work_organization: '',
            work_position: '',
            workStartdate: '',
            workEnddate: '',
          
            candidateID: user.data[0].c_id,
            present: false,
            //id: Date.now()
          });
          break;
    
        default:
          console.warn('Unknown section:', section);
      }
    
      return updatedData;
    });
  };
  
  
  
  const handleInputChange = (e, section, index, field) => {
    const { value } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      updatedData[section][index][field] = value;
      return updatedData;
    });
  };
  
  const handleRemoveSectionItem = (section, index) => {
    setFormData(prevData => {
      const updatedData = { ...prevData };
      updatedData[section].splice(index, 1);
      return updatedData;
    });
  };
  
  const handleSubmitPortfolio = async () => {
    try {
      const portfolioUserId =
        user.data[0].type === 'Provider'
          ? user.data[0].id
          : user.data[0].c_id;
  
      // Upload images to S3 and get their URLs
      const portfolioItemsWithUrls = await Promise.all(
        portfolioItems.map(async (item) => {
          const imageUrls = await Promise.all(
            item.images.map(uploadImageToS3)
          );
          return {
            ...item,
            project_files: imageUrls, // Replace images with URLs
          };
        })
      );
  
      const payload = portfolioItemsWithUrls.map((item) => ({
        portfolio_user_id: portfolioUserId,
        project_objective: item.projectObjective,
        project_description: item.projectDescription,
        industry: item.industry,
        project_files: item.project_files,
      }));
  
      const response = await fetch(
        process.env.REACT_APP_TALENT_CREATEPORTFOLIO,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        if (
          responseData.statusCode === 400 &&
          responseData.body ===
            'Already 5 row values exist for this portfolio_user_id.'
        ) {
          alert(
            'You can only create a maximum of 5 portfolio items. You have already created the maximum allowed.'
          );
        } else {
          setPortfolioItems((prevItems) => [
            ...prevItems,
            {
              projectObjective: '',
              projectDescription: '',
              industry: '',
              images: [],
            },
          ]);
          alert('Portfolio items added successfully!');
  
          // Optionally, call fetchPortfolioData(); to refresh the portfolio items if needed
        }
      } else {
        console.error('Error adding portfolio item:', response.statusText);
        alert('Error adding portfolio item. Please try again.');
      }
    } catch (error) {
      console.error('Error adding portfolio item:', error);
      alert('Error adding portfolio item. Please try again.');
    }
  };
  
 
  const [Uploading, setUploading] = useState(false)

  const [image, setImage] = useState("");


  // console.log(user)

  const dispatch = useDispatch();

  const history = useHistory();

  var scrollTop = function () {
    window.scrollTo(0, 0);
  };

  const inputArr = [{
    degree: "",
    domain: "",
    institution: "",
    startDate: "",
    candidateID: user.data[0].c_id,
    endDate: ""
  }];

  const expArray = [{
    work_organization: "",
    work_position: "",
    workStartdate: "",
    workEnddate: "",
  
    candidateID: user.data[0].c_id,
    present:0,
  }];

  const certificationArray = [{
    cert_name: "",
    candidateID: user.data[0].c_id,
    startDate: "",
    endDate: "",
    certUrl: "",
    certificationInstitution: ""
  }];


  const [arrCert, setarrCert] = useState(certificationArray);

  const [arrexp, setArrexp] = useState(inputArr);
  const [Userexp, setUserexp] = useState(expArray);

  const addInput = () => {
    var joined = arrexp.concat(inputArr[0]);
   
    setArrexp(joined)
  };



  const addExp = () => {
    var joined = Userexp.concat(expArray[0]);
    setUserexp(joined)
  };


  const addCert = () => {
    var joined = arrCert.concat(certificationArray[0]);
    setarrCert(joined)
  };


  useEffect(() => {
    scrollTop();
    setUploading(false)



  }, [])


  const [State, setState] = useState({
    fname: "",
    mname: "",
    lname: "",
    candidateemail: "",
    area: "", 
    github_repo_name: "",
    github_username: "",
    jira_username: "",
    sonarqube_project_key: "",
    country: "",
    primaryskill: "",
    portfolio: "",
    linkedin: "",
    github: "",
    industry: "",
    job_title :"",
    degree: "",
    institution: "",
    domain: "",
    startingDate: "",
    endingDate: "",
    certification: "",
    certificationIntitution: "",
    certificationUrl: "",
    certification_startDate: "",
    certification_endDate: "",
    work_organization: "",
    work_position: "",
    present: "",
    workStartdate: "",
    workEnddate: "",
    secondaryskill: "",
    resetForm: "",
    ratePerhour: "", 
    ratePermonth: "", 
    currency: "", 
    workhours: "",
    about: "",
    techStack: "",
    email: "",
    resume: "",
    coding_assessment_url: "", 
    work_authorization: "",
    timezone: "",
    availability: "",
    hoursperweek: "",
    currentStatus: "",
    availabledate: "",
    profilePicture: "",
    calendlyurl: "",
    talent_code: "",
    profileImg: "",
    errors: {
      blankfield: false,
      passwordmatch: false
    }
  });
  

  


  const onFileUpload = e => {

    setUploading(true)

    const file = e.target.files[0];
    // console.log(file)
    S3FileUpload.uploadFile(file, config)
      .then(data => {

        setState((prevState) => {
          return { ...prevState, resume: data.key };
        });
        setUploading(false)
      })
      .catch(err => {
        // console.log(err); setUploading(false)
      })

  }
  const onFileUploadassesment = e => {

    setUploading(true)
  
    const file = e.target.files[0];
    // console.log(file)
    S3FileUpload.uploadFile(file, config)
      .then(data => {
  
        setState((prevState) => {
          return { ...prevState, coding_assessment_url: data.key };
        });
        setUploading(false)
      })
      .catch(err => {
        console.log(err); setUploading(false)
      })
  
  }
  const onImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    S3FileUpload.uploadFile(file, config)
      .then(data => {
        setState((prevState) => ({
          ...prevState,
          profilePicture: data.key,
        }));
        setUploading(false);
      })
      .catch(err => {
        // Handle the error
        setUploading(false);
      });
  };
  useEffect(() => {
    if (resumeData) {
      setState(prevState => ({
        fname: resumeData.firstname || prevState.fname,
        mname: resumeData.middlename || prevState.mname,
        lname: resumeData.lastname || prevState.lname,
        candidateemail: resumeData.candidateemail || prevState.candidateemail,
        area: resumeData.area || prevState.area,
        country: resumeData.country || prevState.country,
        primaryskill: resumeData.primaryskill || prevState.primaryskill,
        secondaryskill: resumeData.secondaryskill || prevState.secondaryskill,
        portfolio: resumeData.portfoliourl || prevState.portfolio,
        linkedin: resumeData.linkedinurl || prevState.linkedin,
        github: resumeData.githuburl || prevState.github,
        industry: resumeData.industry || prevState.industry,
        job_title:resumeData.job_title || prevState.job_title,
        degree: resumeData.degree || prevState.degree,
        institution: resumeData.institution || prevState.institution,
        domain: resumeData.domain || prevState.domain,
        startingDate: resumeData.startingDate || prevState.startingDate,
        endingDate: resumeData.endingDate || prevState.endingDate,
        certification: resumeData.certification || prevState.certification,
        certificationInstitution: resumeData.certificationInstitution || prevState.certificationInstitution,
        certificationUrl: resumeData.certificationUrl || prevState.certificationUrl,
        certification_startDate: resumeData.certification_startDate || prevState.certification_startDate,
        certification_endDate: resumeData.certification_endDate || prevState.certification_endDate,
        work_organization: resumeData.work_organization || prevState.work_organization,
        work_position: resumeData.work_position || prevState.work_position,
        present: resumeData.present || prevState.present,
        workStartdate: resumeData.workStartdate || prevState.workStartdate,
        workEnddate: resumeData.workEnddate || prevState.workEnddate,
        ratePerhour: resumeData.ratePerhour || prevState.ratePerhour,
        ratePermonth: resumeData.ratePermonth || prevState.ratePermonth,
        currency: resumeData.currency || prevState.currency,
        resetForm: prevState.resetForm, // Not updated from resumeData
        about: resumeData.about || prevState.about,
        workhours: resumeData.workhours || prevState.workhours,
        resume: resumeData.resume || prevState.resume,
        timezone: resumeData.timezone || prevState.timezone,
        coding_assessment_url: resumeData.coding_assessment_url || prevState.coding_assessment_url,
        techStack: resumeData.techStack || prevState.techStack,
        calendlyurl: resumeData.calendlyurl || prevState.calendlyurl,
        talent_code: resumeData.talent_code || prevState.talent_code,
        availability: resumeData.availability || prevState.availability,
        hoursperweek: resumeData.hoursperweek || prevState.hoursperweek,
        currentStatus: resumeData.currentStatus || prevState.currentStatus,
        availabledate: resumeData.availabledate || prevState.availabledate,
        profilePicture: resumeData.profilePicture || prevState.profilePicture,
        region: resumeData.region || prevState.region,
        profileImg: resumeData.profileImg || prevState.profileImg, // Assuming profileImg is optional or should fallback to default
        errors: prevState.errors // Assuming errors are not updated from resumeData
      }));
    }
  }, [resumeData]);
  
  const preparePayload = (section, data = []) => {
    // Define default data structures
    const defaultData = {
      education: [{
        degree: '',
        domain: '',
        institution: '',
        startDate: '',
        endDate: '',
        candidateID: user.data[0].c_id
        // id: Date.now() 
      }],
      certifications: [{
        cert_name: '',
        certificationInstitution: '',
        certUrl: '',
        startDate: 'Invalid date',
        endDate: 'Invalid date',
        candidateID: user.data[0].c_id
        //id: Date.now() 
      }],
      exp: [{
        work_organization: '',
        work_position: '',
        workStartdate: '',
        workEnddate: '',
        
        candidateID: user.data[0].c_id,
        present: false,
        //id: Date.now() 
      }]
    };
  
    // Return provided data if it exists; otherwise, return default data
    return data.length > 0 ? data : defaultData[section] || [];
  };
  
  const addMissingIds = (data) => {
    let idCounter = 1;
    return data.map(item => {
      // Only add 'id' if the item has an 'id' field that is null or undefined
      if (item.hasOwnProperty('id')) {
        return {
          ...item,
          id: item.id !== null && item.id !== undefined ? item.id : idCounter++,
        };
      }
      return item;
    });
  };
  
  const ensureNonEmptyPayload = (data, type) => {
    if (data.length === 0) {
      switch (type) {
        case 'education':
          return [{
            
            degree: '',
            domain: '',
            institution: '',
            startDate: '',
            endDate: '',
            candidateID: user.data[0].c_id
          }];
        case 'certifications':
          return [{
           
            cert_name: '',
            certificationInstitution: '',
            certUrl: '',
            startDate: '',
            endDate: '',
            candidateID: user.data[0].c_id
          }];
        case 'exp':
          return [{
            
            work_organization: '',
            work_position: '',
            workStartdate: '',
            workEnddate: '',
            
            candidateID: user.data[0].c_id,
            present: 0,
          }];
        default:
          return [];
      }
    }
    return data;
  };
  
  const formatData = (data, type) => {
    if (!Array.isArray(data)) {
      return []; // Return an empty array if data is not an array
    }
  
    return data.map(item => {
      switch (type) {
        case 'education':
          return {
           
            degree: item.degree || '',
            domain: item.domain || "",
            institution: item.institution || '',
            startDate: item.startDate || '',
            candidateID: user.data[0].c_id,
            endDate: item.endDate || ''
          };
        case 'certifications':
          return {
            candidateID: user.data[0].c_id,

            cert_name: item.cert_name || '',
            certificationInstitution: item.certificationInstitution || '',
            certUrl: item.certUrl || '',
            startDate: item.startDate || '',
            endDate: item.endDate || ''
          };

        case 'exp':
          return {
            candidateID: user.data[0].c_id,

            work_organization: item.work_organization || '',
            work_position: item.work_position || '',
            workStartdate: item.workStartdate || '',
            workEnddate: item.workEnddate || '',
            present: item.present || 0
          };
        default:
          return item;
      }
    });
  };
  
  
  const handleSubmit = async event => {
    event.preventDefault();
    
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
  
    setUploading(true);
    console.log('Education Data:', arrexp);
    console.log('Experience Data:', Userexp);
    console.log('Certifications Data:', arrCert);
  
    const {
      fname,
      mname,
      lname,
      area,
      industry,
      job_title,
      country,
      techStack,
      calendlyurl,
      talent_code,
      about,
      linkedin,
      github,
      primaryskill,
      secondaryskill,
      workhours,
      chargePerhour,
      portfolio,
      institution,
      workEnddate,
      workStartdate,
      work_organization,
      present,
      work_position,
      certification,
      certificationUrl,
      certification_endDate,
      certification_startDate,
      degree,
      startingDate,
      endingDate,
      domain,
      profilePicture,
      resume,
      timezone,
      coding_assessment_url,
      availability,
      hoursperweek,
      currentStatus, 
      availabledate,
      ratePerhour,
      ratePermonth,
      region,
      currency,
      github_repo_name,
      github_username,
      jira_username,
      sonarqube_project_key,
    } = State;
  
    // Prepare the data object
    const data = {
      id: user.data[0].c_id,
      firstname: fname || null,
      middlename: mname || null,
      lastname: lname || null,
      techStack: techStack || null,
      calendlyurl: calendlyurl || null,
      talent_code: talent_code || null,
      industry: industry || null,
      job_title:job_title ||null,
      primaryskill: primaryskill || null,
      secondaryskill: secondaryskill || null,
      country: country || null,
      linkedinurl: linkedin || null,
      githuburl: github || null,
      workhours: workhours || null,
      portfoliourl: portfolio || null,
      institution: institution || null,
      about: about || null,
      workEnddate: workEnddate || null,
      workStartdate: workStartdate || null,
      work_organization: work_organization || null,
      present: present || null,
      work_position: work_position || null,
      certification: certification.length ? certification : null,
      certificationUrl: certificationUrl || null,
      certification_endDate: certification_endDate || null,
      certification_startDate: certification_startDate || null,
      degree: degree || null,
      startingDate: startingDate || null,
      endingDate: endingDate || null,
      domain: domain || null,
      profilePicture: profilePicture || null,
      resume: resume || null,
      timezone: timezone || null,
      coding_assessment_url:coding_assessment_url ||null,
      availability: availability || null,
      hoursperweek: hoursperweek || null,
      currentStatus:currentStatus ||null,
       availabledate:availabledate ||null,
      ratePerhour: ratePerhour || null,
      ratePermonth: ratePermonth || null,
      region: region || null,
      currency: currency || null,
      github_repo_name: github_repo_name || null,
      github_username: github_username || null,
      jira_username: jira_username || null,
      sonarqube_project_key: sonarqube_project_key || null
    };
  
    try {
      await dispatch(updateProfilecandidate(data, headers));
      const formattedEducation = formatData(formData.education || [], 'education');
      const formattedCertifications = formatData(formData.certifications || [], 'certifications');
      const formattedExp = formatData(formData.exp || [], 'exp');
      
  const promise1 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: formattedEducation, type: "EDU" }, { headers });
  const promise2 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: formattedExp, type: "EXP" }, { headers });
  const promise3 = axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, { educationdata: formattedCertifications, type: "CERT" }, { headers });
//REACT_APP_TALENT_CANDIDATEPROFILETEST
      await Promise.all([promise1, promise2, promise3]);
      await handleSubmitPortfolio();
      dispatch(login(user.data[0].email, "candidate"));
      alert("Candidate Details Successfully Created");
      // window.location.href= "/candidate-dashboard";
      // Optionally navigate to the candidate dashboard
    } catch (error) {
      console.error("Error submitting profile:", error.response ? error.response.data : error.message);
      // Handle error if needed
    }
  };
  



  const onInputChange = e => {
   
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }


  const handleChange = (e, idx) => {
    let clone = [...arrexp];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    // console.log('handlechage 33333 ; ', [...clone]);
    setArrexp([...clone])
  }

  const handleChangeExp = (e, idx, field) => {
    let clone = [...Userexp];
    let obj = clone[idx];
    if (field === 'present') {
      obj.present = e.target.checked ? 1 : 0;
      if (e.target.checked) {
        obj.workEnddate = ''; // Reset end date when present is checked
      }
    } else {
      obj[field] = e.target.value;
    }
    clone[idx] = obj;
    setUserexp(clone);
  }

  const handleChangeCert = (e, idx) => {
    let clone = [...arrCert];
    let obj = clone[idx];
    obj[e.target.id] = e.target.value;
    clone[idx] = obj;
    setarrCert([...clone]);
  }


  const onInputChangeselect = e => {
    setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value // Update the specific state property
    }));
};



  return (

    <div className="container" style={{ "margin-top": "140px" }} id="main">

      <p style={{ fontWeight: "bold", fontSize: "26px" }}>{intl.formatMessage({ id: 'createProfileOpportunity' })}</p>


      <br></br>
      <br></br>
      <div className="row">

        <div className="col-lg-3" ></div>

        <div className="col-lg-7" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px" }}>
        
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h1 className="headsmall">{intl.formatMessage({id:"createProfile"})}</h1>
          <br></br>

          <form onSubmit={handleSubmit}>
            {/* For First Name */}


            <center>
      {image === "" ? (
        <div className="email" style={{
          padding: "0px",
          border: "1px solid #8EAEBC",
          width: "100px",
          height: "100px",
          borderRadius: "50px",
          textAlign: "center",
          position: "relative"
        }}>
          <label htmlFor="profilePicture" style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
          }}>
            <PersonIcon style={{ color: "#8EAEBC" }} />
          </label>
          <input
            type="file"
            onChange={onImageUpload}
            id="profilePicture"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer"
            }}
          />
        </div>
      ) : (
        <img
          src={image}
          alt=""
          style={{
            width: '100px',
            height: '100px',
            marginTop: "50px",
            borderRadius: "50px"
          }}
          id="profilePicture"
        />
      )}
    </center>
            <br></br>
            <h2>Upload Your Resume</h2>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <UploadButton  onClick={handleFileUpload} style={{ marginLeft: '10px' }}>
        Upload
      </UploadButton >

      {uploadStatus && <UploadStatus>{uploadStatus}</UploadStatus>}
      {isLoading && <Loader />}

      <ResumeUploadPageWrapper>
            <p style={{ color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "170px" }} >{intl.formatMessage({ id: 'basicInformation' })}</p>

            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

            <div className="email">
              <label  className="Label">{intl.formatMessage({ id: 'firstName' })} : </label>
              <input
                className="input2"
                placeholder="Enter First Name*"
                type="text"
                id="fname"
                value={State.fname} onChange={onInputChange}
                required
              
              />
            </div>



            <div className="email">
            <label className="Label">{intl.formatMessage({ id: 'middleName' })} : </label>
              <input
                className="input2"
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={State.mname}
                onChange={onInputChange}
              
              />
            </div>

            <div className="email">
            <label className="Label">{intl.formatMessage({ id: 'lastName' })} : </label>
              <input
                className="input2"
                placeholder="Enter Last Name*"
                type="text"
                id="lname"
                value={State.lname}
                onChange={onInputChange}
                required
             
              />
            </div>

            {/* For Middle Name */}


            {/* For Email */}


            <div className="email" >
            <label  className="Label">{intl.formatMessage({ id: 'country' })}  : </label>
              <select
      //value={area}
      className="input2" 
      name="country"
      placeholder="What's your country"
      onChange={onInputChangeselect}       
      value={State.country}     
  
      required
      >
                <option  style={{color:'black'}} value='' defaultValue >Country</option>


{country.map((data) => (
<option> {data.value} </option>
))}

</select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'about' })} : </label>
            
              <textarea
                //value={area}
                type="text"
                className="about2"
                id="about"
                placeholder="About"
                onChange={onInputChange}
                value={State.about}
                
                required
              />
            </div>
                        </div>

            <br></br>
            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "160px" }} >Skills & Expertise</p>
            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

            {/* For Primary Expertise */}
            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'primarySkill' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={area}
                className="input2"
                name="primaryskill" // Ensure this matches the state property
               
              
                value={State.primaryskill}
              
                required
               
              >

<option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='NodeJS' style={{color:'black'}} >NodeJS</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Threejs</option>
<option  value='Tableau' style={{color:'black'}} >Tableau</option>
<option  value='Python' style={{color:'black'}} >Python</option>

<option  value='Quality Assurance' style={{color:'black'}} >Quality Assurance</option>
<option  value='IOS Development (Swift/Objective C)' style={{color:'black'}} >IOS Development (Swift/Objective C)</option>
<option  value='Android Development (Java/Kotlin)' style={{color:'black'}} >Android Development (Java/Kotlin)</option>
<option  value='Devops' style={{color:'black'}} >Devops</option>
<option  value='Cloud (AWS/Azure/GCP)' style={{color:'black'}} >Cloud (AWS/Azure/GCP)</option>



              </select>
            </div>
            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'secondarySkill' })}  : </label>
              <select
                onChange={onInputChangeselect}
                options={secondarea}
                className="input2"
                name="secondaryskill"
                placeholder="Secondary Expertise"
                value={State.secondaryskill}
                list="sskill"
               

                
              >
              <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>

              </select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'techStack' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={techStack}
                className="input2"
                name="techStack"
                placeholder="TechStack"
                value={State.techStack}
                list="sskill"
               

                
              >
                <option value='' defaultValue style={{ color: 'black' }} >techStack</option>

                <option value='MEAN'>MEAN</option>
                        <option value='MERN'>MERN</option>
                        <option value='LAMP'>LAMP</option>
                        <option value='MEVN'>MEVN</option>

              </select>
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'industry' })} : </label>
              <select
                //value={area}
                onChange={onInputChangeselect}
                options={industry}
                className="input2"
                name="industry"
                id="industry"
                placeholder="Industry"
                value={State.industry}
               
                
              >
                <option value='' defaultValue style={{ color: 'black' }} >Industry</option>


                {industry.map((data) => (

                  <option id="industry"> {data.value} </option>
                ))}
              </select>
            </div>
            <div className="email">
      <label className="Label">
        {intl.formatMessage({ id: 'jobTitle' })} :
      </label>
      <select
        onChange={onInputChangeselect}
        className="input2"
        name="job_title"
        id="job_title"
        value={State.job_title}
      >
        <option value='' defaultValue style={{ color: 'black' }}>
          Job Title
        </option>
        {jobTitleOptions.map((data, index) => (
          <option key={index} value={data.value}>
            {data.label}
          </option>
        ))}
      </select>
    </div> 
            </div>
            <br></br>
            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "229px" }}>{intl.formatMessage({ id: 'educationAndCertifications' })}</p>

            <p style={{ marginLeft: "-60px", color: "#8EAEBC", fontSize: "18px", width: "229px" }} className="label2">{intl.formatMessage({ id: 'education' })} </p>

            {/* <button onClick={addInput} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button> */}
<div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

<button type="button" onClick={() => handleAddSectionItem('education')}   className="add">  {intl.formatMessage({ id: 'add' })} +</button>

{formData.education?.map((edu, index) => (
           

                 <>

                  {/* Other Expertise */}
                  <div className="email">
                  <label  className="Label">{intl.formatMessage({ id: 'qualification' })} : </label>
                    <select
                      className="input2"
                     
                    
                      value={edu.degree || ''}
              onChange={(e) => handleInputChange(e, 'education', index, 'degree')}
                      required
                    >
                      <option value='' defaultValue style={{ color: 'black' }} >Qualification</option>
                      <option value='Bachelors' style={{ color: 'black' }} >Bachelors</option>
                      <option value='Masters' style={{ color: 'black' }} >Masters</option>
                      <option value='PHD' style={{ color: 'black' }} >PHD</option>

                    </select>

                  </div>
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'specialization' })} : </label>
                    <input
                      className="input2"
                      
                      type="text"
                  
                      value={edu.domain||''}
                      onChange={(e) =>
                        handleInputChange(e,'education' ,index,'domain')} id="domain"
                      
                    />
                  </div>
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'university' })}   : </label>
                    <input
                      className="input2"
                      placeholder="University"
                      type="text"
                      value={edu.institution || ''}
              onChange={(e) => handleInputChange(e, 'education', index, 'institution')}
             
                     
                   
                      required
                    />
                  </div>




                  {/* Portfolio */}
                  <div className="date" >

                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })} </label>

                      <input
  className="input3"
  type="date"
  value={edu.startDate || ''}
  onChange={(e) => handleInputChange(e, 'education', index, 'startDate')}
  required
/>

                    </div>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })}</label>

                      <input
                        className="input3"
                      
                        type="date"
              value={edu.endDate || ''}
              onChange={(e) => handleInputChange(e, 'education', index, 'endDate')}
              placeholder="End Date"
                        required
                      />
                    </div>
                  </div>
                  <br></br>
                  <RemoveButton type="button" onClick={() => handleRemoveSectionItem('education', index)}>Remove</RemoveButton>
         
                </>
           
           
                        ))}


   </div>
   <br></br>
            <p className="label2" style={{ marginLeft: "-60px", color: "#8EAEBC", fontSize: "18px", width: "229px" }}>Certifications</p>

            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>


            {/* <button onClick={addCert} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button> */}
 <button type="button" onClick={() => handleAddSectionItem('certifications')}  className="add">{intl.formatMessage({ id: 'add' })} +</button>
     
            <br></br>

           
            {formData.certifications?.map((cert, index) => ( 
              <>

                  <div className="password" >
                  <label  className="Label">{intl.formatMessage({ id: 'certifications' })} : </label>
                    <input
                      className="input2"
                      placeholder="Certification"
                      type="text"
                      value={cert.cert_name || ''}
              onChange={(e) => handleInputChange(e, 'certifications', index, 'cert_name')}
             
                  
                    />
                  </div>
                  <div className="password" >
                  <label  className="Label">{intl.formatMessage({ id: 'institute' })} : </label>
                    <input
                      className="input2"
                       type="text"
                      value={cert.certificationInstitution || ''}
              onChange={(e) => handleInputChange(e, 'certifications', index, 'certificationInstitution')}
              placeholder="certificationInstitution"
                    />
                  </div>

                 
                  <div className="password">
                  <label  className="Label">{intl.formatMessage({ id: 'certificationUrl' })} : </label>
                    <input
                      className="input2"
                      placeholder="Certification Url"
                      type="url"
                      value={cert.certUrl || ''}
                     
                        onChange={(e) => handleInputChange(e, 'certifications', index, 'certUrl')}
                     
                    />
                  </div>

                  <div className="date" style={{ display: "flex" }}>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validFrom' })}</label>

                      <input
                        className="input3"
                        placeholder="Valid from"
                        type="date"
                        value={cert.startDate || ''}
              onChange={(e) => handleInputChange(e, 'certifications', index, 'startDate')}
             
                      />
                    </div>
                    <div className="password" style={{ marginLeft: "20px" }}>
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'validTill' })}l</label>

                      <input
                        className="input3"
                        placeholder="Valid till"
                        type="date"
                        value={cert.endDate || ''}
              onChange={(e) => handleInputChange(e, 'certifications', index, 'endDate')}
             
                      />
                    </div>
                  </div>

                  <RemoveButton type="button" onClick={() => handleRemoveSectionItem('certifications', index)}>Remove</RemoveButton>
          </>
        ))}
       
</div>
            <br></br>



            <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "162px" }}>{intl.formatMessage({ id: 'workExperience' })}</p>
            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

            {/* <button onClick={addExp} className="add" aria-label={intl.formatMessage({ id: 'add' })}>
  {intl.formatMessage({ id: 'add' })} +
</button> */}
<button type="button" onClick={() => handleAddSectionItem('exp')}   className="add">{intl.formatMessage({ id: 'add' })} +</button>
   
{formData.exp?.map((exp, index) => (

                 <><div className="email" >
                  <label  className="Label">{intl.formatMessage({ id: 'companyName' })} : </label>
                  <input
                    className="input2"
                    type="text"
                    value={exp.work_organization || ''}
                    onChange={(e) => handleInputChange(e, 'exp', index, 'work_organization')}
                    placeholder="Organization"
                    required
                   
                  />
                </div>

                  {/* GitHub */}
                  <div className="password">
                  <label   className="Label">{intl.formatMessage({ id: 'positionName' })} : </label>
                    <input
                      className="input2"
                      type="text"
              value={exp.work_position || ''}
              onChange={(e) => handleInputChange(e, 'exp', index, 'work_position')}
              placeholder="Position"
                      required
                   
                    />
                  </div>


                  <div className="date" style={{ display: "flex" }}>
                    <div className="password" >
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'startDate' })}</label>

                      <input
                        className="input3"
                        placeholder="Start date"
                        type="date"
                        value={exp.workStartdate || ''}
                        onChange={(e) => handleInputChange(e, 'exp', index, 'workStartdate')}
                       
                        required
                      />
                    </div>
                    {exp.present !== 1 && (
                    <div className="password" style={{ marginLeft: "20px" }}>
                      <label style={{ color: "grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'endDate' })}</label>
                      
                      <input
                        className="input3"
                        placeholder="End date"
                        type="date"
                        value={exp.workEnddate || ''}
              onChange={(e) => handleInputChange(e, 'exp', index, 'workEnddate')}
             
                        required

                      />
                    </div>)}
                    <div className="checkbox" style={{ marginTop: "10px" }}>
  <label style={{ color: "grey", fontWeight: "bold" }}>
    <input
      type="checkbox"
      checked={exp.present === 1}
      onChange={(e) => handleChangeExp(e, index, 'present')}
    />
    &nbsp;{intl.formatMessage({ id: 'present' })}
  </label>
</div>
                  </div>
                  <RemoveButton type="button" onClick={() => handleRemoveSectionItem('exp', index)}>Remove</RemoveButton>
          </>
        ))}
        </div>
        <br></br>

        <p style={{ marginRight: "325px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "162px" }}>Portfolio</p>
        <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>
        <h5>Add Portfolio Item</h5>
        {portfolioItems.map((item, index) => (
        <div key={index}>
          <Input
            type="text"
            name="projectObjective"
            placeholder="Project Objective"
            maxLength={100}
            value={item.projectObjective}
            onChange={(e) => handleInputChangeportfolio(e, index)}
          />
          <Textarea
            name="projectDescription"
            maxLength={300}
            placeholder="Project Description"
            value={item.projectDescription}
            onChange={(e) => handleInputChangeportfolio(e, index)}
          />
          <Selectfield
            name="industry"
            value={item.industry}
            onChange={(e) => handleInputChangeportfolio(e, index)}
          >
            <option value="">Select Industry</option>
            <option value="IT & Software">IT & Software</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Banking and Financial">Banking and Financial</option>
            <option value="Services">Services</option>
          </Selectfield>
          <ImageInput
            type="file"
            name="images"
            accept="image/*"
            multiple
            onChange={(e) => handleImageUpload(e, index)}
          />
            <RemoveButton onClick={() => handleRemoveItem(index)}>Remove</RemoveButton>
        </div>
      ))}
      <Button1 onClick={handleAddItem}>Add Portfolio Item</Button1>
      
        {/* <Button1 onClick={handleAddItem}>Add Item</Button1> */}
      </div>

            <br></br>
            <br></br>
            <br></br>
            <p style={{ marginRight: "321px", color: "#8EAEBC", fontWeight: "bold", fontSize: "18px", width: "170px" }}>{intl.formatMessage({ id: 'otherInformation' })}</p>
            <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'portfolioUrl' })} : </label>
              <input
                className="input2"
                placeholder="Portfolio URL"
                type="url"
                value={State.portfolio}
                onChange={onInputChange}
                id="portfolio"
               

              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'linkedInUrl' })} : </label>
              <input
                className="input2"
                placeholder="LinkedIn Profile URL"
                type="url"
                value={State.linkedin}
                onChange={onInputChange}
                id="linkedin"
               
              />
            </div>
            {/* <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'githubUrl' })} : </label>
              <input
                className="input2"
                placeholder="Github URL"
                type="url"
                value={State.github}
                onChange={onInputChange}
                id="github"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_repo_name' })} : </label>
              <input
                className="input2"
                placeholder="Github Repo Name"
                type="text"
                value={State.github_repo_name}
                onChange={onInputChange}
                id="github_repo_name"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'github_username' })} : </label>
              <input
                className="input2"
                placeholder="Github User Name"
                type="text"
                value={State.github_username}
                onChange={onInputChange}
                id="github_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'jira_username' })} : </label>
              <input
                className="input2"
                placeholder="Jira User Name"
                type="text"
                value={State.jira_username}
                onChange={onInputChange}
                id="jira_username"
                
              />
            </div>
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'sonarqube_project_key' })} : </label>
              <input
                className="input2"
                placeholder="Sonarqube Projectkey"
                type="password"
                value={State.sonarqube_project_key}
                onChange={onInputChange}
                id="sonarqube_project_key"
                
              />
            </div> */}
            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'prefferedWorkHours' })} : </label>
              <select
                //value={area}
                onChange={onInputChangeselect}
                className="input2"
                name="workhours"
                placeholder="Preffered work hours"
                value={State.workhours}
                list="workhours"
                id="workhours"
               
                required
              >
                <option style={{ color: 'black' }} value="">Preffered work hours</option>

                <option>Morning Shift, 9am - 6pm </option>
                <option>Night Shift, 10pm - 7am </option>

              </select>

            </div>

            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'ratePerHour' })} : </label>
              <input
                className="input2"
                placeholder="Rate /hr"
                type="text"
                value={State.ratePerhour}
                onChange={onInputChange}
                id="ratePerhour"
               
               
              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'ratePerMonth' })} : </label>
              <input
                className="input2"
                placeholder="Rate /month"
                type="text"
                value={State.ratePermonth}
                onChange={onInputChange}
                id="ratePermonth"
                
              
              />
            </div>

            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'currency' })} : </label>
              <select
                onChange={onInputChangeselect}
                className="input2"
                name="currency"
                id="currency"
                placeholder="Currency"
                isMulti="true"
                value={State.currency}
                list="skill"
               
              >

                <option value="">Currency</option>
                <option style={{ color: 'black' }} >USD</option>
                <option style={{ color: 'black' }} >INR</option>
                <option style={{ color: 'black' }} >CRC</option>
                <option style={{ color: 'black' }} >Vietnamese Dong</option>
                <option style={{ color: 'black' }} >Colombian Pesos</option>

              </select>
            </div>


            <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'availability' })} : </label>
              <select
                onChange={onInputChangeselect}
                options={area}
                className="input2"
                name="availability"
                id="availability"
                placeholder="Availability"
                isMulti="true"
                value={State.availability}
                list="skill"
                
                required
              >

                <option style={{ color: 'black' }} value="">Availability</option>
                <option style={{ color: 'black' }} value='full time'>Full Time</option>
                <option style={{ color: 'black' }}  value='part time'>Part Time</option>


              </select>
              { State.availability == "part time" ? (<>

<div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'hoursPerWeek' })}</label>

<input
  className="input2"
  placeholder="Available date"
  type="num"
  value={State.hoursperweek}
  onChange={onInputChange}
  id="hoursperweek"
  required
/>
</div>


</>) : (<></>)}
              
            </div>

            <div className="email">
            <label   className="Label">{intl.formatMessage({ id: 'timeZone' })} : </label>

              <select id='timezone' className="input2" name="timezone" onChange={onInputChangeselect} placeholder='Timezone' 
       value={State.timezone} required>
                <option style={{ color: 'black' }} value="">Time Zone</option>
                <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>
                </select>

            </div>

            <div className="email">
  <label   className="Label">{intl.formatMessage({ id: 'currentlyAvailable' })} : </label>
<select  id='currentStatus' className="input2" name="currentStatus" onChange={onInputChangeselect} placeholder='Currently Available'               
 value={State.currentStatus} required>
<option  style={{color:'black'}}  value="">Currently Available</option>
<option  style={{color:'black'}} >Yes</option>
<option  style={{color:'black'}} >No</option>

</select>

</div>

{ State.currentStatus == "No" ? (<>

  <div className="password" >
<label style={{ color:"grey", fontWeight: "bold" }}>{intl.formatMessage({ id: 'availableDate' })}</label>

  <input
    className="input2"
    placeholder="Available date"
    type="date"
    value={State.availabledate}
    onChange={onInputChange}
    id="availabledate"
    required
  />
</div>


</>) : (<></>)}


            {/* <div className="email">
            <label  className="Label">{intl.formatMessage({ id: 'region' })} : </label>
              <select id='region' className="input2" name="region" onChange={onInputChangeselect} placeholder='Region'  
  value={State.region} required>
                <option style={{ color: 'black' }} value="">Region</option>
                <option style={{ color: 'black' }} value='Asia Pacific'>Asia Pacific</option>
                <option style={{ color: 'black' }} value='Latin America'>Latin America</option>
                <option style={{ color: 'black' }} value='North America'>North America</option>
              </select>

            </div> */}
            <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'talentCode' })} : </label>
              <input
                className="input2"
                placeholder="Enter your Talent Code"
                type="text"
                 value={State.talent_code}
                onChange={onInputChange}
                id="talent_code"
                
              />
            </div>
            <div className="password">
            <label   className="Label">{intl.formatMessage({ id: 'calendlyUrl' })} : </label>
              <input
                className="input2"
                placeholder="Enter calendlyurl"
                type="url"
                 value={State.calendlyurl}
                onChange={onInputChange}
                id="calendlyurl"
            
              />
            </div>
            <div className="password">
  <label  className="Label">Coding Assesment : </label>
  <input
    className="input2"
    placeholder="Upload Assesment pdf/docx"
    type="file"
    onChange={onFileUploadassesment}
    id="coding_assessment_url"
    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
 
  />
</div>
            {/* <div className="password">
            <label  className="Label">{intl.formatMessage({ id: 'resume' })} : </label>
              <input
                className="input2"
                placeholder="Upload Resume"
                accept=".doc,.docx,.pdf"

                type="file"
                onChange={onFileUpload}
                id="resume"
                required
             
              />
            </div> */}

             </div>
            <div className="createAccount">
              {Uploading ? (<> <button className="button is-success" style={{ width: "50%", borderRadius: "10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" disabled>{intl.formatMessage({ id: 'creatingProfile' })}...</button></>) : (<> <button className="button is-success" style={{ width: "50%", borderRadius: "10px", boxShadow: "-15px -15px 25px #FFFFFF80" }} type="submit" >Create</button></>)}
            </div>
            </ResumeUploadPageWrapper>
          </form>

        </div>

        <div className="col-lg-3" ></div>


      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>


  );
}

export default CreateProfile;
const RemoveButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e60000;
  }

  &:focus {
    outline: none;
  }
`;
const Input = styled.input`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Selectfield = styled.select`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Textarea = styled.textarea`
  width: 28rem;
  height: 5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const ImageInput = styled.input`
  margin-bottom: 1rem;
`;

const ResumeUploadPageWrapper = styled.div`
  padding: 20px;
  position: relative;
`;

const UploadButton = styled.button`
  margin-left: 10px;
`;

const UploadStatus = styled.p`
  margin-top: 10px;
`;
const Button1 = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;