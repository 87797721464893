import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import { login, CostomerupdateProfile } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import PersonIcon from '@mui/icons-material/Person';
import star from './assets/star.png';
import EditIcon from '@mui/icons-material/Edit';
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import logo from './assets/programmer.png';
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
// import { Margin } from '@mui/icons-material';
// import ManageProfile from '../pages2/ManageProfile';
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}
console.log('cong', process.env.REACT_APP_BUCKETNAME)
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}



const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [

    
  
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
   { 
    value: 'IT & Softwear', label: 'IT & Softwear'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]

const Costomerprofile = () => {
  
  const token = useAuthToken();

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [Imagedata, setImagedata] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 


  

  const dispatch = useDispatch();

  const myBucket = new AWS.S3(S3Config);


  const history = useHistory();

  const [num, setNum] = useState({
    country: '+1', // Default country code, you can change it to the desired default value
    phone_number: '',
  });

  const onInputChangenum = (event) => {
    const { id, value } = event.target;
    setNum((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const countryCodes = ['+1', '+44', '+91', '+81', '+86']; // Add more country codes as needed



  const [Edit, setEdit] = useState(false); 

  const [Image, setImage] = useState(""); 
  const [Uploading, setUploading] = useState(false)
 



  const [State, setState] = useState({
    fname: user.data[0].customer_firstname,
    mname: user.data[0].customer_middlename,
    lname: user.data[0].customer_lastname,
    candidateemail: user.data[0].customer_lastname,
    country : user.data[0].customer_country,
    about:  user.data[0].about,
    email:  user.data[0].email,   
    phone_number:  user.data[0].phone_number,     
      
    
    profilePicture : user.data[0].profilePicture
    })
  
    const onImageUpload = e => {

      const file = e.target.files[0];


  if (file) {
    setImage(URL.createObjectURL(file));
    // console.log("2#$#$#$#$#$$#$",URL.createObjectURL(file));

  }



      S3FileUpload.uploadFile(file, config)
      .then(data => {

        
         const location = data.key;

  
        setState((prevState) => {
         return { ...prevState, [e.target.id]: location };
      });
      setUploading(false)
    })    
    .catch(err => {
    
     // console.log(err,"$%%%%%%%%%%%%%%%%%%%% : ",file,config);    
       setUploading(false)
    })
      
 
  }



  const clearErrorState = () => {
    setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  const handleSubmit = async event => {
    event.preventDefault();
    // Form validation
 
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
   

    const { fname, mname, lname, email, country,about, profilePicture,phone_number} = State;

    const data = {
        customer_id : user.data[0].customer_id,
        customer_firstname: fname, phone_number: phone_number,customer_middlename : mname, customer_lastname : lname, customer_lastname : lname, customer_country : country, about: about, profilePicture : profilePicture 
  }

 
    dispatch(CostomerupdateProfile(data,headers)).then((err, datas)=>{

      alert("ClientAdmin Details Successfully Updated");
      setTimeout(() => {
        window.location.reload();            
      }, 2000);

      // window.location.href = "/client-dashboard";
    });             
  };



  const onInputChange = e => {
   
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  const onInputChangeselect = e => {
    
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  async function generatePreSignedPutUrl11() {
    try {
        // Define the request parameters
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: process.env.REACT_APP_CLOUDFRONT +user.data[0].profilePicture
            })
        };
  
        // Make the API call
        const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
        
        // Parse the response as JSON
        const data = await response.json();
  
        // Extract the signed URL from the response data
        const signedUrl = JSON.parse(data.body).signedUrl;
  
        // Use the signed URL as needed
        setAuthImage(signedUrl);
    } catch (error) {
        console.error("Error generating signed URL:", error);
    }
  }

  useEffect(() => {

    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
  
  }, [])
  
  


  const { profileImg} = State
    return (

      <div className="container">


      
      <br></br>
      <br></br>
        <div className="row">
  
  
  
  
       
  



       <div className="col-lg-3" >

      {/* <span style={{ marginLeft: '20px',  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' }} onClick={() => { setEdit(false) }}> <a 
          style={{  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' 
        }} href="/client-dashboard" >Dashboard</a>  >  Manage Profile  >   </span> */}

      </div> 
 
      <div className="col-lg-6" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1, padding: "60px"  }}>

      {Edit == false ? (

<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "50px", height : "50px" , borderRadius : "50px", backgroundColor: "#8EAEBC", float: "right"}}>
<EditIcon  style={{ color :  "white", marginLeft : "12px", marginTop: "12px" }} onClick={() => { setEdit(true) }} />

  </div>
): (<></>)}

<br></br>
<br></br>
<br></br>
<br></br>
<br></br> 

       {user.data[0].profile == 0 ? (  <h1 className="headsmall">Create profile</h1>  ) : ('')}


      

      



{Edit == false ? (

<>
<h1 className="headsmall"  style={{ "margin-top": "40px" }}>Manage Profile</h1>

<center>



          
{   user.data[0].profilePicture == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
             
            <PersonIcon  style={{ color :  "#8EAEBC", textAlign :'center'}}/>


            </div>) : (  <img src={AuthImage}   style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img> )}
            
             <br></br>
             <p style={{ marginLeft : "-1px", color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.fname + " " + State.lname}</p>
             <p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >{State.email}</p>
             <p style={{ marginLeft : "-5px", color :  "grey", width: "100%", fontSize: "18px" }} >{State.country} 
               {/* |   <img src={star} style={{width:"11.49px",height:'11px'}}></img><text style={{width:'18px' ,height:'17px', fontSize:'12px'}}>4.5</text> */}
  </p>
  <p style={{ marginLeft : "-15px", color :  "grey", width: "100%", fontSize: "16px" }} >{State.phone_number}  </p>
 
 
 
             </center>

       <p style={{ marginLeft : "-4px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", width: "170px",float: "left" }} >Basic information</p>
<p style={{ marginLeft : "-55px", color :  "grey", width: "170px", fontSize: "18px" }} >About</p>

<p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "18px", float: "left",marginLeft: "5px"  }} >{State.about}</p>
</>) : ( <>
  {/* <h1 className="headsmall"  style={{ "margin-bottom": "60px" }}>Update Profile</h1> */}

     
  <center>
                    {Image == "" ? (<div  style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height: "100px", borderRadius: "50px", textAlign: "center" }}>
                    
                        {AuthImage == null ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{ width: '100px', height: '100px', borderRadius: "50px",textAlign: "center" }}
                          ></img>
                        )


                          : (
                          
                            <img src={AuthImage} alt='' style={{ width: '100px', height: '100px',textAlign: "center", borderRadius: "50px" }} id="profilePicture" ></img>

                          )}

<span>
                        <input type="file" style={{ marginLeft: '40px', width: '5rem', height: '3rem', borderRadius: "50px"  }} className="profileclass" onChange={onImageUpload} id="profilePicture" />
                        <EditIcon style={{color: 'white', width:'25px', height: '25px', marginLeft:"-4rem",marginTop:"-1rem", backgroundColor: '#03045e', boxShadow: 'rgb(255, 255, 255) -15px -15px 25px', borderRadius: '50px' }} onChange={onImageUpload}
              />
                      </span>
                      {/* <span style={{marginTop:"-10px",marginLeft:"20px"}}> hhgjh</span> */}

                    </div>) : (<img src={Image} alt='' style={{ width: '100px', height: '100px', marginTop: "30px", borderRadius: "50px" }} id="profilePicture" ></img>)}</center>
            
     <br></br>
     
          <form onSubmit={handleSubmit}>
            {/* For First Name */}


            <div className="email">
              <label  className="Label">First Name : </label>
              <input
                className="input2" 
                placeholder="Enter First Name"
                type="text"
                id="fname"
value={State.fname}  onChange={onInputChange}
required
              />
            </div>

            <div className="email">
              <label className="Label">Middle Name : </label>
              <input
                className="input2" 
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={State.mname}
                onChange={onInputChange}
              />
            </div>

                <div className="email">
                    <label className="Label">Last Name : </label>
              <input
                className="input2" 
                placeholder="Enter Last Name"
                type="text"
                id="lname"
                value={State.lname}
                onChange={onInputChange}
                required
              />
            </div>
            <div className="email">
      <label className="Label">Phone Number:</label>
      
      {/* <select
        style={{width:'6rem'}}
          className="country-code"
          id="country"
          value={State.phone_number}
          onChange={onInputChange}
        >
          {countryCodes.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </select> */}
      <input
        className="input2"
        placeholder="Enter Phone number"
        type="tel"
        id="phone_number"
        value={State.phone_number}
        onChange={onInputChange}
        required
      />
    </div>

            {/* For Middle Name */}
           

            {/* For Email */}

            
            <div className="email" >
           <label  className="Label">country  : </label>
   <select
    //value={area}
      className="input2" 
      name="country"
      placeholder="What's your country*"
      onChange={onInputChangeselect}       
      value={State.country}     
   
      required    
      >
              <option  style={{color:'black'}} value='' defaultValue >Country</option>


{country.map((data) => (
 
<option> {data.value} </option>
))}

</select>






            </div>

            {/* <div className="email">
              <label  className="Label">About : </label>
              <textarea
                  //value={area}
                  type="text"
                  className="input2"
                  id="about"
                  placeholder="About"
                  onChange={onInputChange}
                  value={State.about}
               
                  required
                  />
            </div> */}

            <div className="createAccount">
              <button className="button is-success" style={{ width : "50%"}} type="submit"> {user.data[0].profile == 0 ? ( "Create") : ("Save")}</button>
            </div>
         
           </form>

           </>)}

</div>        

           <div className="col-lg-3"></div>


        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>


    );
  }

export default Costomerprofile;
